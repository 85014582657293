import * as React from 'react';
import * as moment from 'moment';
import { Box, ListGroupItem, Palette } from '@mycelium/core';
import { EventsByDate } from '../../utils/eventsUtil';
import ScheduleEventItem from './ScheduleEventItem';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';

interface ScheduleListGroupItemsProps {
  eventsByDate: EventsByDate;
  accountScheduledSessionIds: number[];
}

const ScheduleItems: React.SFC<ScheduleListGroupItemsProps> = ({ eventsByDate, accountScheduledSessionIds }) => {
  return (
    <React.Fragment>{
      Object.keys(eventsByDate).map((date: string, index: number) => {
        const mDate = moment(date);
        return (
          <React.Fragment key={index}>
            <ListGroupItem header>
              <strong>{mDate.format('dddd')}</strong>, {mDate.format('MMMM D')}
            </ListGroupItem>
            {eventsByDate[date].length === 0 &&
            <ListGroupItem style={{ textAlign: 'center' }}>
              <Box fg={Palette.medGrey}>No classes are currently scheduled.</Box>
            </ListGroupItem>
            }
            {eventsByDate[date].map((event: SelfServiceAPI.Event, index: number) => {
              const isBooked: boolean = accountScheduledSessionIds.indexOf(event.id) > -1;
              return <ScheduleEventItem key={index} event={event} isBooked={isBooked}/>;
            })}
          </React.Fragment>
        );
      })
    }</React.Fragment>
  );
};

export default ScheduleItems;
