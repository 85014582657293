import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import { BasicType, Box, Loader, Palette } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { requestActiveAccountMetadata } from '../../ducks/accountMetadataDuck';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import BookingEventNotFound from '../presentation/BookingEventNotFound';
import EventDetails from '../presentation/EventDetails';
import { scrollService } from '../utility/scrollService';
import { cancelScheduledSession, fetchEvent } from '../../ducks/bookingUiDuck';
import appHistory from '../../services/appHistory';

export interface EventManageContainerProps {
  eventId: number;
  startTimeStartAt: string;
  locationId: number;
}

export interface EventManageComponentDataProps {
  isFetching: boolean;
  locationInfo: SelfServiceAPI.Location;
  event?: SelfServiceAPI.Event;
}

interface EventManageComponentEventProps {
  onCancelScheduledSession: (event: SelfServiceAPI.Event) => void;
}

interface EventManageComponentProps extends EventManageComponentDataProps, EventManageComponentEventProps {
}

export class EventManageComponent extends React.Component<EventManageComponentProps> {
  constructor(props: EventManageComponentProps) {
    super(props);
    this.handlerCancelScheduledSession = this.handlerCancelScheduledSession.bind(this);
  }

  private getEventIsCancelableByAsText(): string {
    const { event } = this.props;
    if (!event) return '';
    return moment.tz(event.cancelByDate, moment.tz.guess()).format('MMM D[,] YYYY [at] h:mm A z');
  }

  private handlerCancelScheduledSession(): void {
    const { event, onCancelScheduledSession } = this.props;
    if (!event) return;

    if (window.confirm('Are you sure you no longer want to attend this session? Select \'OK\' to cancel this session.')) {
      onCancelScheduledSession(event);
    }
  }

  private renderCancelButton(): JSX.Element {
    return (
      <a onClick={this.handlerCancelScheduledSession}>
        <Box dp="inline" fg={Palette.blue} style={{ cursor: 'pointer' }}>
          <BasicType sizePx={13}>Cancel Session</BasicType>
        </Box>
      </a>
    );
  }

  private renderCancelContent(): JSX.Element | null {
    const { event, isFetching } = this.props;
    if (!event) return null;

    if (isFetching) {
      return (
        <Box my={3}>
          <Loader/>
        </Box>
      );
    }

    if (moment(event.startTime).isSameOrBefore(moment())) {
      return (
        <Box my={3}>
          This session cannot be cancelled because the start time has passed.
        </Box>
      );
    }

    if (moment(event.cancelByDate).isSameOrBefore(moment())) {
      return (
        <React.Fragment>
          <Box my={3}>
            If you no longer wish to attend this session, you can cancel without a refund.
          </Box>
          {this.renderCancelButton()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Box my={3}>
          If you no longer wish to attend this session, you can cancel
          by <strong>{this.getEventIsCancelableByAsText()}</strong> to receive a session refund.
        </Box>
        {this.renderCancelButton()}
      </React.Fragment>
    );
  }

  public render(): JSX.Element {
    const {
      isFetching,
      locationInfo,
      event,
    } = this.props;

    if (isFetching && !event) {
      return (
        <Box textAlign="center" px={4}>
          <Loader/>
        </Box>
      );
    }
    if (!event) return <BookingEventNotFound/>;

    const {
      subject,
      startTime,
      endTime,
      staffFirstName,
      staffLastName,
      maxAttendees,
      openSlots,
    } = event;

    return (
      <React.Fragment>
        <Box xs={{ px: 3, pt: 3 }} md={{ p: 0 }}>
          <Link to="../../schedule">
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '8px' }}/>
            Back to Schedule
          </Link>
        </Box>
        <Box my="3" xs={{ px: 3 }} md={{ p: 0 }}>
          <h1>
            <BasicType size="2">Manage Session</BasicType>
          </h1>
        </Box>
        <EventDetails
          subject={subject}
          startTime={startTime}
          endTime={endTime}
          staffFirstName={staffFirstName}
          staffLastName={staffLastName}
          staffId={event.staffId}
          staffProfilePictureHash={event.staffProfilePictureHash}
          maxAttendees={maxAttendees}
          openSlots={openSlots}
          locationInfo={locationInfo}
          scheduledSession={false}
        >
          {this.renderCancelContent()}
        </EventDetails>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: SelfServiceCombinedReducers): EventManageComponentDataProps => {
  const event = state.bookingUiReducer.event;

  return {
    event,
    isFetching: (
      state.accountMetadataReducer.isFetching
      || state.scheduleReducer.isFetching
      || state.bookingUiReducer.isFetchingEvent
      || state.bookingUiReducer.isUpdatingBookingStatus
    ),
    locationInfo: state.appReducer.locationInfo,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: EventManageContainerProps): EventManageComponentEventProps => {
  const { locationId, eventId, startTimeStartAt } = ownProps;

  dispatch(requestActiveAccountMetadata(locationId));
  dispatch(fetchEvent(locationId, eventId, startTimeStartAt));

  scrollService.scrollToTop();

  return {
    onCancelScheduledSession: (event: SelfServiceAPI.Event) => {
      dispatch(cancelScheduledSession(locationId, event, () => {
        dispatch(requestActiveAccountMetadata(locationId));
        appHistory.push('../../schedule');
      }));
    },
  };
};

const EventManageContainer: React.ComponentClass<EventManageContainerProps> =
  connect<EventManageComponentDataProps,
    EventManageComponentEventProps,
    EventManageContainerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EventManageComponent);

export default EventManageContainer;
