import * as React from 'react';
import { Box, Palette } from '@mycelium/core';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import ScheduledSession from './ScheduledSession';

interface ScheduledSessionsProps {
  locationId: number;
  scheduledSessions: SelfServiceAPI.AccountScheduledSession[];
}

class ScheduledSessions extends React.Component<ScheduledSessionsProps> {
  render() {
    const { locationId, scheduledSessions } = this.props;
    return (
      <Box p="3" fg={Palette.black} bg={Palette.white} textAlign="left">
        <Box mb={3}>
          <span>Scheduled sessions </span>
          <Box dp="inline" fg={Palette.medGrey}>({scheduledSessions.length})</Box>
        </Box>
        <ul>
          {scheduledSessions.map((scheduledSession: SelfServiceAPI.AccountScheduledSession, index: number) => (
            <ScheduledSession
              key={index}
              locationId={locationId}
              scheduledSession={scheduledSession}
            />
          ))}
        </ul>
      </Box>
    );
  }
}

export default ScheduledSessions;
