import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, BasicType, Palette } from '@mycelium/core';
import { getSelfServicePasswordValidation } from '../../utils/selfServicePasswordValidator';

interface NewPasswordContainerProps {
  password: string;
}

class NewPasswordContainer extends React.Component<NewPasswordContainerProps, {}> {
  private static renderValidation(valid: boolean, label: string) {
    const icon = valid
      ? faCheckCircle
      : faExclamationCircle;

    return (
      <Box>
        <BasicType sizePx={13} style={{ lineHeight: '26px' }}>
          <Box dp="inline-block" mr={2}>
            <BasicType sizePx={16}>
              <FontAwesomeIcon icon={icon}/>
            </BasicType>
          </Box>
          <span style={{ opacity: valid ? 0.5 : 1 }}> {label}</span>
        </BasicType>
      </Box>
    );
  }

  render() {
    const { password } = this.props;
    const validation = getSelfServicePasswordValidation(password);

    return (
      <Box p="3" ba="1" borderColor={Palette.darkBlue} borderRound>
        <Box mb={3}>
          <BasicType sizePx={13} style={{ opacity: 0.73, fontWeight: 'bold' }}>
            Password requirements:
          </BasicType>
        </Box>
        {NewPasswordContainer.renderValidation(validation.capital, 'At least one capital letter')}
        {NewPasswordContainer.renderValidation(validation.lower, 'At least one lowercase letter')}
        {NewPasswordContainer.renderValidation(validation.number, 'At least one number')}
        {NewPasswordContainer.renderValidation(validation.length, 'At least eight characters')}
      </Box>
    );
  }
}

export default NewPasswordContainer;
