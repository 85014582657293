import * as React from 'react';
import ScheduleContainer from '../../components/containers/ScheduleContainer';

interface LocationScheduleProps {
  match: any;
}

class LocationSchedule extends React.PureComponent<LocationScheduleProps> {
  render() {
    return (
      <ScheduleContainer locationId={this.props.match.params.locationId} />
    );
  }
}

export default LocationSchedule;
