import * as React from 'react';

export interface ImageProps {
  src: string;
  fallback?: JSX.Element;
  style?: React.CSSProperties;
}

export interface ImageState {
  hasError: boolean;
}

class Image extends React.Component<ImageProps, ImageState> {
  constructor(props: ImageProps) {
    super(props);
    this.state = {
      hasError: !props.src,
    };
    this.handleError = this.handleError.bind(this);
  }

  private handleError(): void {
    this.setState({ hasError: true });
  }

  public render(): JSX.Element | null {
    const { src, fallback, style } = this.props;
    const { hasError } = this.state;

    if (hasError) return fallback || null;

    return (
      <img
        src={src}
        onError={this.handleError}
        style={style}
      />
    );
  }
}

export default Image;
