import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthorizedUserState } from '../../ducks/authorizedUserDuck';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { RETURN_URL_PARAM } from '../../constants/constants';
import { locationParams } from '../../constants/routing';

export interface AuthorizedRouteProps extends RouteProps {
  useLoginReturnUrl?: boolean;
}

export interface AuthorizedRoutePropsWithConnect extends AuthorizedRouteProps, StateProps {
}

export const AuthorizedRoute: React.SFC<AuthorizedRoutePropsWithConnect> = (props) => {
  const { component: Component, useLoginReturnUrl, authorizedUserState, render, ...rest } = props;

  const renderRoute = (props: RouteComponentProps<locationParams>) => {
    const redirectTo = {
      ...props.location
    };

    if (props.match.params.locationId) {
      redirectTo.pathname = `/locations/${props.match.params.locationId}/login`;
    } else {
      redirectTo.pathname = '/';
    }

    if (useLoginReturnUrl) {
      const returnUrl = encodeURIComponent(props.location.pathname + props.location.hash + props.location.search);
      redirectTo.search = `?${RETURN_URL_PARAM}=${returnUrl}`;
    }

    let component: any = <Redirect to={redirectTo} />;
    if (authorizedUserState.authorized) {
      if (Component) {
        component = <Component {...props} />;
      } else if (render) {
        component = render(props);
      }
    }

    return component;
  };

  return (
    <Route render={renderRoute} {...rest} />
  );
};

interface StateProps {
  authorizedUserState: AuthorizedUserState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    authorizedUserState: state.authorizedUserReducer
  };
};

const authorizedRouteWithConnect: React.ComponentClass<AuthorizedRouteProps> =
  connect<StateProps, any, AuthorizedRouteProps>(mapStateToProps)(AuthorizedRoute);

export default authorizedRouteWithConnect;
