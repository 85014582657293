import * as React from 'react';
import * as qs from 'qs';
import { Box } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import BookingsContainer  from './BookingsContainer';
import BookingEventNotFound from '../presentation/BookingEventNotFound';
import appHistory from '../../services/appHistory';

export interface BookingsParamsProps {
  locationId: number;
}

const BookingsParams: React.SFC<BookingsParamsProps> = (props) => {
  const { locationId } = props;
  const { eventId, startTimeStartAt } = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });

  return (
    <Box mw="14">
      <Box xs={{ px: 3, pt: 3  }} md={{ p: 0 }}>
        <Link to="schedule">
          <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '8px' }}/>
          Back to Schedule
        </Link>
      </Box>
      {eventId && startTimeStartAt && locationId
        ? <BookingsContainer
          locationId={locationId}
          eventId={eventId}
          startTimeStartAt={startTimeStartAt}
        />
        : <BookingEventNotFound/>
      }
    </Box>
  );
};

export default BookingsParams;
