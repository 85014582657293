import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';

export interface TitleContainerProps {
  children?: string[] | string;
}

export interface TitleComponentsProps {
  clubName?: string;
  locationName?: string;
  children?: any[] | string;
}

export const TitleComponent: React.SFC<TitleComponentsProps> = (props) => {
  const { clubName, locationName, children } = props;

  const childrenText: string =
    Array.isArray(children)
      ? children.join('')
      : children as string;

  const title: string =
    [
      clubName,
      locationName,
      childrenText,
    ].filter(t => !!t).join(' ')
    || 'Club OS Self Service';

  return <Helmet><title>{title}</title></Helmet>;
};

const mapStateToProps = (state: SelfServiceCombinedReducers): TitleComponentsProps => {
  const {
    scheduleReducer: scheduleState,
    accountMetadataReducer: { scheduledSessions },
  } = state;
  return {
    clubName: state.appReducer.locationInfo.clubName,
    locationName: state.appReducer.locationInfo.locationName,
  };
};

const TitleContainer: React.ComponentClass<TitleContainerProps> =
  connect<TitleComponentsProps, {}, TitleContainerProps>(
    mapStateToProps)(TitleComponent);

export default TitleContainer;
