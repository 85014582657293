import * as React from 'react';
import { Box, TextInput, Palette } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export interface PasswordInputProps {
  value?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  frosted?: boolean;
  required?: boolean;
  large?: boolean;
  hasError?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IPasswordInputState {
  show: boolean;
}

class PasswordInput extends React.Component<PasswordInputProps, IPasswordInputState> {
  public static defaultProps: PasswordInputProps = {
    value: '',
    placeholder: '',
    readOnly: false,
    frosted: false,
    required: false,
    large: false,
    hasError: false,
    onChange: () => undefined,
  };

  constructor(props: PasswordInputProps) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleVisibilityButtonClick = this.handleVisibilityButtonClick.bind(this);
  }

  private handleVisibilityButtonClick(): void {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  private renderVisibilityButton(): JSX.Element {
    const { large } = this.props;
    const { show } = this.state;
    const iconName: IconProp = show ? faEyeSlash : faEye;

    const style: React.CSSProperties = {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: large ? '10px 16px' : '6px 12px',
      cursor: 'pointer',
      userSelect: 'none',
    };

    return (
      <Box
        style={style}
        fg={Palette.grey}
        onClick={this.handleVisibilityButtonClick}
      >
        <FontAwesomeIcon icon={iconName}/>
      </Box>
    );
  }

  public render(): JSX.Element {
    const {
      name,
      placeholder,
      readOnly,
      frosted,
      required,
      large,
      hasError,
      innerRef,
      onKeyPress,
      onChange,
    } = this.props;

    const {
      show,
    } = this.state;

    const containerStyle: React.CSSProperties = { position: 'relative' };
    const inputStyle: React.CSSProperties = { paddingRight: '75px' };

    const type: string = show ? 'text' : 'password';

    return (
      <Box
        style={containerStyle}
      >
        <TextInput
          name={name}
          type={type}
          style={inputStyle}
          placeholder={placeholder}
          readOnly={readOnly}
          frosted={frosted}
          required={required}
          large={large}
          hasError={hasError}
          ref={innerRef}
          onKeyPress={onKeyPress}
          onChange={onChange}
        />
        {this.renderVisibilityButton()}
      </Box>
    );
  }
}

export default PasswordInput;
