import * as React from 'react';
import { Box, Button } from '@mycelium/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { AppState } from '../../ducks/appDuck';

const styles: {
  loginContainer: string,
  loginCard: string,
  heading2: string,
  white: string
} = require('../../styles/login.module.css');

export interface TokenExpiredProps {
}

export class TokenExpired extends React.Component<TokenExpiredProps & StateProps, {}> {
  render() {
    const { locationId } = this.props.appState.locationInfo;
    return (
      <div className={styles.loginContainer}>
        <Box p={5} className={styles.loginCard}>
          <Box mb={5}>
            <h2 className={styles.heading2}>
              Verification Link Expired
            </h2>
          </Box>
          <Box mb={4}>
            <p className={styles.white}>
              You have clicked an expired verification link. If you did not finish setting up your account, you can register again.
            </p>
          </Box>
          <Box>
            <Link to={`/locations/${locationId}/create-account`}>
              <Button stretch inverse large mb={2}>
                Create Account
              </Button>
            </Link>
            <Link to={`/locations/${locationId}/login`}>
              <Button stretch inverse large>
                Login
              </Button>
            </Link>
          </Box>
        </Box>
      </div>
    );
  }
}

interface StateProps {
  appState: AppState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    appState: state.appReducer
  };
};

const tokenExpiredWithConnect: React.ComponentClass<TokenExpiredProps> =
  connect<StateProps, any, {}>(mapStateToProps)(TokenExpired);

export default tokenExpiredWithConnect;
