import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mycelium/core';
import { AppState } from '../../ducks/appDuck';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';

export interface RegistrationCompleteProps {
}

export interface RegistrationCompletePropsWithConnect extends RegistrationCompleteProps, StateProps {
}

// TODO: Use font system from Mycelium once it's available, and remove inline styles
export const RegistrationComplete: React.SFC<RegistrationCompletePropsWithConnect> = (props) => (
  <React.Fragment>
    <Box mb="3">
      <h1 style={{ fontSize: '2em', fontWeight: 400, textAlign: 'center' }}>You're all set!</h1>
    </Box>
    <Box mb="3" style={{ textAlign: 'center' }}>
      <p>You can use your account at any gym or studio using Club OS.{' '}
      Get started by checking out {props.appState.locationInfo.clubName}'s schedule.</p>
    </Box>
    <Link to={`/locations/${props.appState.locationInfo.locationId}/schedule`}>
      <Button stretch large inverse>View Schedule</Button>
    </Link>
  </React.Fragment>
);

interface StateProps {
  appState: AppState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    appState: state.appReducer,
  };
};

const registrationCompleteWithConnect: React.ComponentClass<RegistrationCompleteProps> =
  connect<StateProps, any, {}>(mapStateToProps)(RegistrationComplete);

export default registrationCompleteWithConnect;
