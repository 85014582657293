import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import selfServiceStore from '../store/selfServiceStore';
import appHistory from '../services/appHistory';
import AppLayout from './layouts/AppLayout';
import ErrorPage404 from './errorPages/404';

export default () => {
  return (
    <Provider store={selfServiceStore}>
      <Router history={appHistory}>
        <Switch>
          <Route path="/locations/:locationId" render={(props: any) => <AppLayout {...props} />} />
          <Route path="/404" render={({ location: { search } }) => <ErrorPage404 locationSearch={search} />} />
          <Route path="/" render={() => <ErrorPage404 />} />
        </Switch>
      </Router>
    </Provider>
  );
};
