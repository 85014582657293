import * as React from 'react';
import { Flex, Box, Palette } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faTags, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Image from './Image';
import { getFormattedDates } from '../../utils/classDetailsUtil';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import { getStaffProfileUrl } from '../../data/selfServiceApi';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const styles: {
  card: string,
  subject: string,
  dateInfo: string,
  textInfo: string,
} = require('../../styles/eventDetails.module.css');

export interface EventDetailsProps {
  subject: string;
  startTime: string;
  endTime: string;
  staffId: number;
  staffProfilePictureHash: string;
  staffFirstName: string;
  staffLastName: string;
  locationInfo: SelfServiceAPI.Location;
  maxAttendees: number;
  openSlots: number;
  scheduledSession?: boolean;
  children?: any;
}

class EventDetails extends React.PureComponent<EventDetailsProps, any> {

  constructor(props: EventDetailsProps) {
    super(props);
  }

  private renderInfoIcon(fontName: IconProp): JSX.Element {
    return (
      <Box
        fg={Palette.grey}
        dp="inline-block"
        mr="2"
        style={{ minWidth: '20px' }}
      >
        <FontAwesomeIcon icon={fontName}/>
      </Box>
    );
  }

  private renderInstructorIcon(): JSX.Element {
    const { staffId, staffProfilePictureHash } = this.props;
    return (
      <Image
        src={getStaffProfileUrl(staffId, staffProfilePictureHash)}
        fallback={this.renderInfoIcon(faUserCircle)}
        style={{ height: '16px', margin: '0 10px 0 2px', borderRadius: '10px' }}
      />
    );
  }

  // TODO: how do we get timezone? moment(date).format('zz') returns blank string
  public render() {
    const {
      subject,
      startTime,
      endTime,
      staffFirstName,
      staffLastName,
      locationInfo,
      scheduledSession,
      maxAttendees,
      openSlots,
      children,
    } = this.props;
    const formattedDates = getFormattedDates(startTime, endTime);
    return (
      <Box p="4" className={styles.card}>
        <Box mb="3" className={styles.dateInfo}>
          <span>{formattedDates.fullDateLong}</span><br />
          <span>{formattedDates.startTime} - {formattedDates.endTime}</span>
          {!scheduledSession && (
            <span>{` (${formattedDates.duration})`}</span>
          )}
        </Box>
        <Box mb="3" className={styles.subject}>
          {subject}
        </Box>
        <Box  fg={Palette.darkGrey}>
          <Box mb="3">
            {this.renderInstructorIcon()}
            <Box dp="inline" className={styles.textInfo}>
              {staffFirstName} {!scheduledSession && <span>{staffLastName}</span>} {scheduledSession && <span>{staffLastName[0]}.</span>}
            </Box>
          </Box>
          <Box mb="3">
            {this.renderInfoIcon(faTags)}
            <Box className={styles.textInfo}>
              <strong>{openSlots}</strong>/{maxAttendees} spot{openSlots > 1 ? 's' : ''} left
            </Box>
          </Box>
          {!scheduledSession && <Flex>
            <Box>
              {this.renderInfoIcon(faMapMarkerAlt)}
            </Box>
            <Box className={styles.textInfo}>
              {locationInfo.clubName}<br />
              {locationInfo.address1}<br />
              {locationInfo.address2 && <span>{locationInfo.address2}<br /></span>}
              {locationInfo.city}, {locationInfo.state} {locationInfo.zip}
            </Box>
          </Flex>}
        </Box>
        {children}
      </Box>
    );
  }
}

export default EventDetails;
