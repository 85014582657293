import * as moment from 'moment';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';
import { BATCH_SCHEDULE_DAYS } from '../constants/constants';

export interface EventsByDate {
  [date: string]: SelfServiceAPI.Event[];
}

export const getDateKey = (date: string | moment.Moment): string => {
  return moment(date).startOf('day').toISOString();
};

export const groupOrderedEventsByDate = (events: SelfServiceAPI.Event[]): EventsByDate => {
  return events.reduce((acc: EventsByDate, event: SelfServiceAPI.Event) => {
    acc[getDateKey(event.startTime)].push(event);
    return acc;
  }, buildEventsDates(events));
};

const buildEventsDates = (events: SelfServiceAPI.Event[]): EventsByDate => {
  if (!events.length) return {};
  const start = getDateKey(events[0].startTime);
  const end = getDateKey(events[events.length - 1].startTime);
  const mLast = moment(start);
  const mCurrent = moment(end);
  const diff = mCurrent.diff(mLast, 'days');
  const output = { [start]: [] };
  for (let i = 0; i < diff; i = i + 1) {
    output[getDateKey(mLast.add(1, 'days'))] = [];
  }
  return output;
};

export interface ResolveBatchDateRange {
  startDatetime: string;
  endDatetime: string;
}

export const resolveBatchDateRange = (lastBatchEndDate: string | null = null): ResolveBatchDateRange => {
  let fromDatetime: moment.Moment;
  if (lastBatchEndDate) {
    fromDatetime = moment(lastBatchEndDate).add(1, 'day');
  } else {
    fromDatetime = moment();
  }
  const startDatetime = fromDatetime.startOf('day').toISOString();
  const endDatetime = fromDatetime.add(BATCH_SCHEDULE_DAYS - 1, 'day').endOf('day').toISOString();
  return {
    startDatetime,
    endDatetime
  };
};
