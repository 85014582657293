import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as qs from 'qs';
import * as jwtDecode from 'jwt-decode';
import { Flex } from '@mycelium/core';
import { validateRegistrationToken } from '../../utils/registrationUtils';
import { RegistrationToken } from '../../typings/registration';
import Title from './Title';
import RegistrationFormContainer from './RegistrationFormContainer';
import TokenExpired from '../presentation/TokenExpired';

const styles: {
  headerOffset: string,
} = require('../../styles/app.module.css');

export interface RegistrationTokenContainerProps extends RouteComponentProps<{}> {
}

export interface RegistrationTokenContainerState {
  tokenString: string;
  token?: RegistrationToken;
}

export class RegistrationTokenContainer extends React.Component<RegistrationTokenContainerProps, RegistrationTokenContainerState> {

  constructor(props: RegistrationTokenContainerProps) {
    super(props);
    this.state = {
      tokenString: '',
      token: undefined
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (params.t) {
      try {
        const token = jwtDecode<RegistrationToken>(params.t);
        if (validateRegistrationToken(token)) {
          this.setState({ token, tokenString: params.t });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    return (
      <Flex justifyContent="center" className={styles.headerOffset}>
        <Title>Registration</Title>
        {this.state.token ? (
          <RegistrationFormContainer token={this.state.token} tokenString={this.state.tokenString} />
        ) : (
          <TokenExpired />
        )}
      </Flex>
    );
  }
}

const registrationTokenContainerWithRouter = withRouter(RegistrationTokenContainer);

export default registrationTokenContainerWithRouter;
