import reduxThunk from 'redux-thunk';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import appReducer, { AppState } from '../ducks/appDuck';
import authorizedUserReducer, { AuthorizedUserState } from '../ducks/authorizedUserDuck';
import permissionsReducer, { PermissionsState } from '../ducks/permissionsDuck';
import scheduleReducer, { ScheduleState } from '../ducks/scheduleDuck';
import bookingUiReducer, { BookingUiState } from '../ducks/bookingUiDuck';
import bookingsReducer, { BookingsState } from '../ducks/bookingsDuck';
import { connectAuthorization } from '../services/authorizationService';
import loginReducer, { LoginState } from '../ducks/loginDuck';
import accountReducer, { AccountState } from '../ducks/accountDuck';
import registrationReducer, { RegistrationState } from '../ducks/registrationDuck';
import accountMetadataReducer, { AccountMetadataState } from '../ducks/accountMetadataDuck';

export type SelfServiceCombinedReducers = {
  appReducer: AppState,
  authorizedUserReducer: AuthorizedUserState,
  permissionsReducer: PermissionsState,
  scheduleReducer: ScheduleState,
  bookingUiReducer: BookingUiState,
  bookingsReducer: BookingsState,
  loginReducer: LoginState,
  accountReducer: AccountState,
  accountMetadataReducer: AccountMetadataState,
  registrationReducer: RegistrationState
};

const selfServiceReducers = combineReducers({
  appReducer,
  authorizedUserReducer,
  permissionsReducer,
  scheduleReducer,
  bookingUiReducer,
  bookingsReducer,
  loginReducer,
  accountReducer,
  accountMetadataReducer,
  registrationReducer
});

const composeEnhancers = (<any>window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const selfServiceStore = createStore(selfServiceReducers, composeEnhancers(applyMiddleware(reduxThunk)));

// https://stackoverflow.com/a/35417246
connectAuthorization(selfServiceStore);

export default selfServiceStore;
