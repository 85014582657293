import { Action, Reducer } from 'redux';
import { postAuthenticationCredentials } from '../data/selfServiceApi';

// Action types
export enum LoginActionType {
  LoginUserSuccess = 'login_SUCCESS',
  LoginUserAttempt = 'login_ATTEMPT',
  LoginUserFailed = 'login_USER_FAILED',
  LoginNetworkFailed = 'login_NETWORK_FAILED',
  ResetFailedLogin = 'login_RESET'
}

export interface LoginState {
  isFetching: boolean;
  loginFailed: boolean;
  networkError: boolean;
}

export type LoginAction = {
  type: LoginActionType;
};

// Action Creators
export const attemptLoginUser =
  (locationId: number, username: string, password: string, history: any, initReturnUrl?: string) => (dispatch: any) => {
    dispatch({ type: LoginActionType.LoginUserAttempt });
    postAuthenticationCredentials(locationId, { username, password })
      .then((() => {
        dispatch(loginSuccess(history, initReturnUrl));
      }))
      .catch(((error) => {
        if (
          error && error.response && (
            error.response.status === 401
            || error.response.status === 404  // todo: delete me once the api returns 401
          )
        ) {
          dispatch(loginUserFailure());
        } else {
          dispatch(loginNetworkFailure());
        }
      }));
  };

export const loginSuccess = (history: any, initReturnUrl?: string) => (dispatch: any, getState: any) => {
  const { locationId } = getState().appReducer.locationInfo;
  const redirect = initReturnUrl ? initReturnUrl : `/locations/${locationId}/schedule`;
  dispatch({ type: LoginActionType.LoginUserSuccess });
  history.push(redirect);
};

export const loginUserFailure = () => ({
  type: LoginActionType.LoginUserFailed
});

export const loginNetworkFailure = () => ({
  type: LoginActionType.LoginNetworkFailed
});

export const loginReset = () => ({
  type: LoginActionType.ResetFailedLogin
});

// Reducer
export const loginInitialState: LoginState = {
  isFetching: false,
  loginFailed: false,
  networkError: false,
};

const loginReducer: Reducer<LoginState> = (state: LoginState = loginInitialState, action: Action & LoginAction) => {
  switch (action.type) {
    case LoginActionType.LoginUserAttempt:
      return {
        ...state,
        isFetching: true,
      };
    case LoginActionType.LoginUserSuccess:
      return {
        ...state,
        isFetching: false,
      };
    case LoginActionType.LoginUserFailed:
      return {
        ...state,
        isFetching: false,
        loginFailed: true,
      };
    case LoginActionType.LoginNetworkFailed:
      return {
        ...state,
        isFetching: false,
        networkError: true,
      };
    case LoginActionType.ResetFailedLogin:
      return {
        ...loginInitialState,
      };
    default:
      return state;
  }
};

export default loginReducer;
