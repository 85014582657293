import { Action, Reducer } from 'redux';
import { fetchScheduleByEventsRequest } from '../data/selfServiceApi';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';
import appHistory from '../services/appHistory';

// Action types
export enum ScheduleActionType {
  FetchScheduleByEventsRequest = 'schedule_FETCH_SCHEDULE_BY_EVENTS_REQUEST',
  ReceiveLocationScheduleSuccess = 'schedule_RECEIVE_LOCATION_SCHEDULE_SUCCESS',
  ReceiveLocationScheduleError = 'schedule_RECEIVE_LOCATION_SCHEDULE_ERROR',
  UpdateEvent = 'schedule_UPDATE_EVENT',
  ClearSchedule = 'schedule_CLEAR_SCHEDULE',
}

export interface ScheduleState {
  events: SelfServiceAPI.Event[];
  isFetching: boolean;
  hasMore: boolean;
  lastEventsRequest?: SelfServiceAPI.EventsRequest;
}

export type ScheduleAction = {
  type: ScheduleActionType;
  events: SelfServiceAPI.Event[];
  lastEventsRequest?: SelfServiceAPI.EventsRequest;
};

// Action Creators
export const fetchSchedule = (eventsRequest: SelfServiceAPI.EventsRequest, onLoad: (success: boolean) => void) => (dispatch: any) => {
  dispatch({ type: ScheduleActionType.FetchScheduleByEventsRequest });
  fetchScheduleByEventsRequest(eventsRequest)
    .then(((events: SelfServiceAPI.Event[]) => {
      dispatch(receiveLocationScheduleSuccess(events, eventsRequest));
      onLoad(true);
    }))
    .catch((error) => {
      if (
        error.response &&
        // todo: delete the 500, once the api is fixed not returning 500 instead of 404 or so
        [404, 500].indexOf(error.response.status) > -1
      ) {
        appHistory.push(`/404?path=${window.location.pathname}`);
        return;
      }

      console.error('fetchSchedule: cannot fetch', error);
      dispatch(receiveLocationScheduleError());
      onLoad(false);
    });
};

const receiveLocationScheduleSuccess = (events: SelfServiceAPI.Event[], lastEventsRequest: SelfServiceAPI.EventsRequest) => ({
  type: ScheduleActionType.ReceiveLocationScheduleSuccess,
  events,
  lastEventsRequest,
});

const receiveLocationScheduleError = () => ({
  type: ScheduleActionType.ReceiveLocationScheduleError,
});

export const updateScheduleEvent = (event: SelfServiceAPI.Event) => (dispatch: any) => {
  dispatch({
    type: ScheduleActionType.UpdateEvent,
    events: [event],
  });
};

// Reducer
export const scheduleInitialState: ScheduleState = {
  events: [],
  isFetching: false,
  hasMore: true,
};

const scheduleReducer: Reducer<ScheduleState> = (state: ScheduleState = scheduleInitialState, action: Action & ScheduleAction) => {
  switch (action.type) {
    case ScheduleActionType.FetchScheduleByEventsRequest:
      return {
        ...state,
        isFetching: true
      };
    case ScheduleActionType.ReceiveLocationScheduleSuccess:
      const hasEvents = action.events.length !== 0;
      return {
        ...state,
        events: state.events.concat(action.events),
        lastEventsRequest: action.lastEventsRequest,
        hasMore: hasEvents,
        isFetching: false
      };
    case ScheduleActionType.ReceiveLocationScheduleError:
      return {
        ...state,
        isFetching: false
      };
    case ScheduleActionType.UpdateEvent:
      return {
        ...state,
        events: state.events.map(iterateEvent => {
          let outputEvent = iterateEvent;
          action.events.forEach(updatedEvent => {
            if (
              iterateEvent.id === updatedEvent.id &&
              iterateEvent.locationId === updatedEvent.locationId &&
              iterateEvent.startTime === updatedEvent.startTime
            ) {
              outputEvent = updatedEvent;
            }
          });
          return outputEvent;
        }),
      };
    case ScheduleActionType.ClearSchedule:
      return {
        ...scheduleInitialState,
      };
    default:
      return state;
  }
};

export default scheduleReducer;
