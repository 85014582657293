import * as React from 'react';
import { Navigation } from '@mycelium/core';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';

interface LocationNavigationProps {
  locationInfo: SelfServiceAPI.Location | any;
}

class LocationNavigation extends React.Component<LocationNavigationProps, object> {
  public render() {
    const { locationInfo } = this.props;
    const { locationId } = locationInfo;
    const links = [
      { text: 'Schedule', link: `/locations/${locationId}/schedule` },
    ];
    return <Navigation locationInfo={locationInfo} links={links} />;
  }
}

export default LocationNavigation;
