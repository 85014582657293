import * as React from 'react';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../constants/constants';

const styles: {
  whiteLink: string,
} = require('../../styles/login.module.css');

const CreateAccountTerms: React.SFC<{}> = () => (
  <span>By selecting the Create Account button, I agree to the{' '}
    <a href={TERMS_OF_SERVICE_URL} className={styles.whiteLink}>Terms of Service</a> and{' '}
    <a href={PRIVACY_POLICY_URL} className={styles.whiteLink}>Privacy Policy</a>.
  </span>
);

export default CreateAccountTerms;
