import * as React from 'react';
import { connect } from 'react-redux';
import { BasicType, Box, Button, Loader, Palette } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { requestActiveAccountMetadata } from '../../ducks/accountMetadataDuck';
import appHistory from '../../services/appHistory';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import BookingDetails from '../presentation/BookingDetails';
import BookingEventNotFound from '../presentation/BookingEventNotFound';
import EventDetails from '../presentation/EventDetails';
import { updateBookingStatus, fetchEvent } from '../../ducks/bookingUiDuck';
import { BookingStatus } from '../../constants/data';
import { scrollService } from '../utility/scrollService';

export interface BookingsContainerProps {
  eventId: number;
  startTimeStartAt: string;
  locationId: number;
}

export interface BookingsComponentDataProps {
  isFetching: boolean;
  locationInfo: SelfServiceAPI.Location;
  bookableUnits: number;
  memberServiceName: string;
  event?: SelfServiceAPI.Event;
}

interface BookingsComponentEventProps {
  onRequestData: () => void;
  onUpdateBookingStatusConfirmed: (event: SelfServiceAPI.Event, cbCompleted: (success: boolean) => void) => void;
}

interface BookingsComponentProps extends BookingsComponentDataProps, BookingsComponentEventProps {
}

// TODO (future sprint): Remove from component state when we have information for the logged in account's booked events
interface BookingsContainerState {
  eventBooked: boolean;
}

export class BookingsComponent extends React.Component<BookingsComponentProps, BookingsContainerState> {

  constructor(props: BookingsComponentProps) {
    super(props);
    this.handleBookClick = this.handleBookClick.bind(this);
    this.state = {
      eventBooked: false,
    };

    props.onRequestData();
  }

  static handleReturnClick() {
    appHistory.push('schedule');
  }

  public componentDidMount(): void {
    scrollService.scrollToTop();
  }

  private handleBookClick(): void {
    const { event, onUpdateBookingStatusConfirmed } = this.props;
    if (event) {
      // TODO (future sprint): Handle in Redux, cbCompleted is a patch
      onUpdateBookingStatusConfirmed(event, success => {
        if (success) {
          this.setState({ eventBooked: true });
        }
      });
    }
  }

  public render() {
    const {
      isFetching,
      locationInfo,
      event,
      memberServiceName,
      bookableUnits,
    } = this.props;

    if (isFetching) {
      return (
        <Box textAlign="center" py={4}>
          <Loader/>
        </Box>
      );
    }
    if (!event) return <BookingEventNotFound/>;

    const {
      subject,
      startTime,
      endTime,
      staffFirstName,
      staffLastName,
      maxAttendees,
      openSlots,
    } = event;

    return (
      <React.Fragment>
        <Box my="3" xs={{ px: 3 }} md={{ p: 0 }}>
          <h1>
            {!this.state.eventBooked ? (
              <BasicType size="2">Complete Reservation</BasicType>
            ) : (
              <React.Fragment>
                <FontAwesomeIcon icon={faCheckCircle} color={Palette.green} size="4x"/>
                <BasicType size="2"> Your session is reserved!</BasicType>
              </React.Fragment>
            )}
          </h1>
        </Box>
        <EventDetails
          subject={subject}
          startTime={startTime}
          endTime={endTime}
          staffFirstName={staffFirstName}
          staffLastName={staffLastName}
          staffId={event.staffId}
          staffProfilePictureHash={event.staffProfilePictureHash}
          maxAttendees={maxAttendees}
          openSlots={openSlots}
          locationInfo={locationInfo}
          scheduledSession={false}
        />
        {!this.state.eventBooked ? (
          <BookingDetails
            isFetching={false}
            locationInfo={locationInfo}
            memberServiceName={memberServiceName}
            bookableUnits={bookableUnits}
            handleBookClick={this.handleBookClick}
          />
        ) : (
          <React.Fragment>
            <Box my={3}>
              {bookableUnits === 0 ? (
                <span>You have scheduled all of your available sessions.</span>
              ) : (
                <span>You still have {bookableUnits} {memberServiceName} sessions available to schedule.</span>
              )}
            </Box>
            <Button role="link" onClick={BookingsComponent.handleReturnClick}>Return to Schedule</Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: SelfServiceCombinedReducers): BookingsComponentDataProps => {
  const event = state.bookingUiReducer.event;
  const memberService = event && state.accountMetadataReducer.memberServices[event.memberServiceId];

  return {
    event,
    isFetching: (
      state.accountMetadataReducer.isFetching
      || state.scheduleReducer.isFetching
      || state.bookingUiReducer.isFetchingEvent
      || state.bookingUiReducer.isUpdatingBookingStatus
    ),
    locationInfo: state.appReducer.locationInfo,
    memberServiceName: memberService && memberService.name || '',
    bookableUnits: memberService && memberService.bookableUnits || 0,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: BookingsContainerProps): BookingsComponentEventProps => {
  const { locationId, eventId, startTimeStartAt } = ownProps;
  return {
    onRequestData: () => {
      dispatch(requestActiveAccountMetadata(locationId));
      dispatch(fetchEvent(locationId, eventId, startTimeStartAt));
    },
    onUpdateBookingStatusConfirmed: (event: SelfServiceAPI.Event, cbCompleted: (success: boolean) => void) => {
      if (event) dispatch(updateBookingStatus(locationId, event, BookingStatus.Confirmed, cbCompleted) as any);
    },
  };
};

const BookingsContainer: React.ComponentClass<BookingsContainerProps> =
  connect<BookingsComponentDataProps,
    BookingsComponentEventProps,
    BookingsContainerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(BookingsComponent);

export default BookingsContainer;
