import * as React from 'react';
import { Route, Switch } from 'react-router';
import RegistrationTokenContainer from '../containers/RegistrationTokenContainer';
import LocationSchedule from '../presentation/LocationSchedule';
import AuthorizedRoute from './AuthorizedRoute';
import AccountContainer from '../containers/AccountContainer';
import BookingsParams from '../containers/BookingsParamsContainer';
import EventManageContainer from '../containers/EventManageContainer';
import LoginContainer from '../containers/LoginContainer';
import CreateAccountContainer from '../containers/CreateAccountContainer';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';

interface LocationSwitchProps {
  locationInfo: SelfServiceAPI.Location | any;
}

class LocationSwitch extends React.Component<LocationSwitchProps, {}> {
  render() {
    return (
      <Switch>
        <Route path="/locations/:locationId/registration" component={RegistrationTokenContainer} />
        <Route path="/locations/:locationId/schedule" component={LocationSchedule} />
        <AuthorizedRoute path="/locations/:locationId/account" component={AccountContainer} />
        <AuthorizedRoute
          path="/locations/:locationId/bookings"
          useLoginReturnUrl
          render={(props) => <BookingsParams locationId={props.match.params.locationId}/>}
        />
        <AuthorizedRoute
          path="/locations/:locationId/manage/:eventId/:startTimeStartAt"
          useLoginReturnUrl
          render={(props) =>
            <EventManageContainer
              locationId={props.match.params.locationId}
              eventId={props.match.params.eventId}
              startTimeStartAt={props.match.params.startTimeStartAt}
            />
          }
        />
        <Route
          path="/locations/:locationId/login"
          render={(props) => <LoginContainer {...props} />}
        />
        <Route
          path="/locations/:locationId/create-account"
          render={(props) => <CreateAccountContainer {...props} locationId={props.match.params.locationId} />}
        />
      </Switch>
    );
  }
}

export default LocationSwitch;
