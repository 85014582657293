import { Action, Reducer } from 'redux';
import { verifyAccountByEmailAndLocationId } from '../data/selfServiceApi';

// Action types
enum AccountActionType {
  VerifyAccountAttempt = 'account_VERIFY',
  VerifyAccountSuccess = 'account_VERIFY_SUCCESS',
  VerifyAccountFailed = 'account_VERIFY_FAILED',
  VerifyAccountReset = 'account_VERIFY_RESET',
  VerifyAccountResubmitSuccess = 'account_RESUBMIT_VERIFY_SUCCESS'
}

export interface AccountState {
  isFetching: boolean;
  verifyFailed: boolean;
  emailSent: boolean;
  resubmitSuccess: boolean;
}

export type AccountAction = {
  type: AccountActionType;
};

// Action Creators
export const attemptAccountVerification = (email: string, locationId: number, isResubmit: boolean = false) => (dispatch: any) => {
  dispatch({ type: AccountActionType.VerifyAccountAttempt });
  verifyAccountByEmailAndLocationId(email, locationId)
  .then((() => {
    dispatch(verifySuccess());
    if (isResubmit) {
      dispatch(resubmitVerifySuccess());
    }
  }))
  .catch(((data) => {
    dispatch(verifyFailure());
  }));
};

export const resetAccountVerification = () => ({
  type: AccountActionType.VerifyAccountReset
});

export const verifySuccess = () => ({
  type: AccountActionType.VerifyAccountSuccess
});

export const resubmitVerifySuccess = () => ({
  type: AccountActionType.VerifyAccountResubmitSuccess
});

export const verifyFailure = () => ({
  type: AccountActionType.VerifyAccountFailed
});

export const verifyAccountReset = () => ({
  type: AccountActionType.VerifyAccountReset
});

// Reducer
export const verifyAccountInitialState: AccountState = {
  isFetching: false,
  verifyFailed: false,
  emailSent: false,
  resubmitSuccess: false
};

const accountReducer: Reducer<AccountState> = (state: AccountState = verifyAccountInitialState, action: Action & AccountAction) => {
  switch (action.type) {
    case AccountActionType.VerifyAccountAttempt:
      return {
        ...state,
        isFetching: true,
        verifyFailed: false,
        resubmitSuccess: false
      };
    case AccountActionType.VerifyAccountSuccess:
      return {
        ...state,
        isFetching: false,
        verifyFailed: false,
        emailSent: true
      };
    case AccountActionType.VerifyAccountFailed:
      return {
        ...state,
        isFetching: false,
        verifyFailed: true,
        emailSent: false
      };
    case AccountActionType.VerifyAccountReset:
      return {
        ...state,
        isFetching: false,
        verifyFailed: false,
        emailSent: false,
        resubmitSuccess: false
      };
    case AccountActionType.VerifyAccountResubmitSuccess:
      return {
        ...state,
        isFetching: false,
        verifyFailed: false,
        emailSent: true,
        resubmitSuccess: true
      };
    default:
      return state;
  }
};

export default accountReducer;
