import { Store } from 'redux';
import * as jwtDecode from 'jwt-decode';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';
import { updateAuthorizedUser, authorizedUserDefaultState, AuthorizedUserState } from '../ducks/authorizedUserDuck';
import { permissionsDefaultState, updatePermissions } from '../ducks/permissionsDuck';

// TODO: type permissions
let dispatchAuthorization: (authorizedUser: AuthorizedUserState, permissions: {}) => void;
export const ACCESS_TOKEN_NAME = 'accessToken';
const ACCESS_TOKEN_PREFIX = 'Bearer ';

export type AuthorizationToken = {
  exp: number;
  iat: number;
  sub: string;
  Name: string;
  SubjectId: string;
  CantBuyTime: number;
  ProfilePicHash: string;
};

const constructTokenString = (token: string | null): string => {
  let tokenString = '';
  if (token) {
    tokenString = ACCESS_TOKEN_PREFIX + token;
  }
  return tokenString;
};

export const destructTokenString = (tokenString: string): string => {
  return tokenString.slice(ACCESS_TOKEN_PREFIX.length, tokenString.length);
};

export const handleTokenUpdate = (tokenString: string) => {
  let authorizedUser = authorizedUserDefaultState;
  let permissions = {
    purchaseBy: 0
  };
  if (tokenString) {
    try {
      const token: AuthorizationToken = jwtDecode<AuthorizationToken>(tokenString);
      authorizedUser = {
        authorized: true,
        usersName: token.Name,
        usersProfilePicHash: token.ProfilePicHash
      };
      permissions = {
        purchaseBy: token.CantBuyTime
      };
    } catch (error) {
      console.error(error);
    }
  }
  dispatchAuthorization(authorizedUser, permissions);
};

export const resetAuthorization = (callback: () => void) => {
  window.localStorage.removeItem(ACCESS_TOKEN_NAME);
  dispatchAuthorization(authorizedUserDefaultState, permissionsDefaultState);
  callback();
};

export const validateAuthorization = (response: SelfServiceAPI.Response) => {
  const currentTokenString = window.localStorage.getItem(ACCESS_TOKEN_NAME) || '';
  const newTokenString = (response && response.headers.authorization) ?
    destructTokenString(response.headers.authorization) : currentTokenString;
  if (currentTokenString !== newTokenString || newTokenString === '') {
    window.localStorage.setItem(ACCESS_TOKEN_NAME, newTokenString);
    handleTokenUpdate(newTokenString);
  }
};

export const getAuthorizationHeader = (): SelfServiceAPI.AuthorizationHeader => {
  return {
    authorization: constructTokenString(window.localStorage.getItem(ACCESS_TOKEN_NAME))
  };
};

// noinspection JSUnusedLocalSymbols
dispatchAuthorization = (authorizedUser, permissions) => {
  console.warn('not connected to store');
};

export const connectAuthorization = (store: Store) => {
  dispatchAuthorization = (authorizedUser, permissions) => {
    store.dispatch(updateAuthorizedUser(authorizedUser));
    store.dispatch(updatePermissions(permissions));
  };
  handleTokenUpdate(constructTokenString(window.localStorage.getItem(ACCESS_TOKEN_NAME)));
};
