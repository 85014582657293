import * as moment from 'moment';
import { RegistrationToken } from '../typings/registration';

export const validateRegistrationToken = (token: RegistrationToken) => {
  return moment().unix() <= (token.exp || 0);
};

export const exampleValidRegistrationTokenString = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' +
  'eyJmaXJzdE5hbWUiOiJCb2IiLCJsYXN0TmFtZSI6IkZyYXBwbGVzIiwibG9jYXRpb' +
  '25JZCI6MSwiZXhwIjoyMTY0NzMzMzk2LCJpYXQiOjIxNjQ3MzMzOTYsImVtYWlsIj' +
  'oiYmZAYmZzdHVkaW9zLmNvbSJ9.Q36ACb06zIrsSQGGQpaMBN9cp62f1NttP7GIlKLihTI';
