import { Action, Reducer } from 'redux';

/**
 * NOT TESTED
 * Temporary way to track if events are booked
 */

// Action types
export enum BookingsActionType {
  AddBookings = 'bookings_ADD_BOOKINGS',
  RemovingBookings = 'bookings_REMOVING_BOOKINGS'
}

export type BookingsAction = {
  type: BookingsActionType,
  bookings: string[]
};

// Action Creators
export const addBookings = (eventIds: string[]) => {
  return {
    type: BookingsActionType.AddBookings,
    bookings: eventIds
  };
};

export const removeBookings = (eventIds: string[]) => {
  return {
    type: BookingsActionType.RemovingBookings,
    bookings: eventIds
  };
};

// Reducer
export interface BookingsState {
  bookings: string[];
}

const initialBookingsState: BookingsState = {
  bookings: []
};

const bookingsReducer: Reducer<BookingsState> = (state: BookingsState = initialBookingsState, action: Action & BookingsAction) => {
  switch (action.type) {
    case BookingsActionType.AddBookings:
      return {
        ...state,
        bookings: state.bookings.concat(action.bookings)
      };
    case BookingsActionType.RemovingBookings:
      return {
        ...state,
        bookings: state.bookings.filter((event) => action.bookings.indexOf(event) < 0)
      };
    default:
      return state;
  }
};

export default bookingsReducer;
