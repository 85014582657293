import * as React from 'react';
import * as Promise from 'bluebird';

export type Validator = () => Promise<any>;
export type ValidatorMap = { name: string, validator: Validator };

// TODO: Move to @mycelium/validation or some other manageable project

// Props that will be inherited from the HOC
export interface WithFormValidationProps {
  registerValidators: (validatorsMap: ValidatorMap[]) => void;
  unregisterValidators: (names: string[]) => void;
  validate: (beforeValidate?: () => void, afterValidate?: (errors: {}[]) => void) => void;
  reset: () => void;
  errors: any[];
}

// Props that can be passed to the HOC
export interface FormValidationComponentProps {
  beforeValidate?: () => void; // TODO: Remove
  afterValidate?: (errors: {}[]) => void; // TODO: Remove
}

export interface Error {
  message: string;
  ref: React.RefObject<HTMLInputElement>;
  inputName: string;
}

export interface FormValidationComponentState {
  validators: {
    [key: string]: Validator;
  };
  errors: Error[];
}

const withFormValidation = <P extends {}>(TargetComponent: React.ComponentType<P & WithFormValidationProps>) => {
  type CombinedProps = P & FormValidationComponentProps;

  class FormValidationComponent extends React.Component<CombinedProps, FormValidationComponentState> {

    constructor(props: CombinedProps) {
      super(props);
      this.registerValidators = this.registerValidators.bind(this);
      this.unregisterValidators = this.unregisterValidators.bind(this);
      this.validate = this.validate.bind(this);
      this.reset = this.reset.bind(this);
      this.state = {
        validators: {},
        errors: []
      };
    }

    // Improve performance by only re-rendering if something other than validators changed
    // Note that this overrides any other state change if the validators change. That shouldn't happen with the current API,
    // but something to look out for if the API changes
    shouldComponentUpdate(nextProps: any, nextState: FormValidationComponentState) {
      let shouldUpdate = true;
      if (nextState.validators !== this.state.validators) {
        shouldUpdate = false;
      }
      return shouldUpdate;
    }

    // How validation is built - note that you'll want to avoid conflicts since name is used as a key
    registerValidators(validatorsMap: ValidatorMap[]) {
      const nextValidators = this.state.validators;
      validatorsMap.forEach((validatorMap) => {
        nextValidators[validatorMap.name] = validatorMap.validator;
      });
      this.setState({ validators: nextValidators });
    }

    unregisterValidators(names: string[]) {
      const nextState = { ...this.state };
      names.forEach((name) => {
        delete nextState.validators[name];
      });
      this.setState(nextState);
    }

    // Resolve the promise for each validator and check for all rejections
    validate(beforeValidate?: () => void, afterValidate?: (errors: {}[]) => void) {
      const errors: Error[] = [];

      // TODO: Remove
      if (this.props.beforeValidate) {
        this.props.beforeValidate();
      }

      if (beforeValidate) {
        beforeValidate();
      }

      const validatorKeys = Object.keys(this.state.validators);
      Promise.all(validatorKeys.map((name) => {
        const validator = this.state.validators[name];

        // Implementation of settleAll: http://bluebirdjs.com/docs/api/reflect.html
        return validator().reflect();
      })).each((inspection: Promise.Inspection<TypeError>) => {
        if (inspection.isRejected()) {
          errors.push(inspection.reason());
        }
      }).then(() => {
        this.setState({ errors });

        // TODO: Remove
        if (this.props.afterValidate) {
          this.props.afterValidate(errors);
        }

        if (afterValidate) {
          afterValidate(errors);
        }
      });
    }

    reset() {
      this.setState({
        errors: []
      });
    }

    render() {
      const {
        beforeValidate,
        afterValidate,
        ...rest
      } = this.props as FormValidationComponentProps;
      const injectedProps: WithFormValidationProps = {
        registerValidators: this.registerValidators,
        unregisterValidators: this.unregisterValidators,
        validate: this.validate,
        reset: this.reset,
        errors: this.state.errors
      };
      return (
        // @ts-ignore // todo: fix TS error with P
        <TargetComponent
          { ...rest }
          { ...injectedProps }
        />
      );
    }
  }

  return FormValidationComponent;
};

export default withFormValidation;
