import { Action, Dispatch, Reducer } from 'redux';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';
import { cancelScheduledEvent, fetchEventByEventId, patchAccountEventAttendee } from '../data/selfServiceApi';
import { requestActiveAccountMetadata } from './accountMetadataDuck';
import { BookingStatus } from '../constants/data';
import { appErrorMessage, appSuccessMessage } from './appDuck';
import { updateScheduleEvent } from './scheduleDuck';

// Note: This duck is for one UI view at a time
// Action types
export enum BookingUiActionType {
  FetchEventRequest = 'bookingUi_FETCH_EVENT_REQUEST',
  FetchEventSuccess = 'bookingUi_FETCH_EVENT_SUCCESS',
  FetchEventError = 'bookingUi_FETCH_EVENT_ERROR',
  UpdateBookingStatusRequest = 'bookingUi_UPDATE_BOOKING_STATUS_REQUEST',
  UpdateBookingStatusSuccess = 'bookingUi_UPDATE_BOOKING_STATUS_SUCCESS',
  UpdateBookingStatusFailed = 'bookingUi_UPDATE_BOOKING_STATUS_FAILED',
  UpdateEvent = 'bookingUi_UPDATE_EVENT',
  CancelEventRequest = 'bookingUi_CANCEl_EVENT_REQUEST',
  CancelEventSuccess = 'bookingUi_CANCEL_EVENT_SUCCESS',
  CancelEventError = 'bookingUi_CANCEL_EVENT_ERROR',
}

export type BookingUiAction = {
  type: BookingUiActionType;
  event: SelfServiceAPI.Event,
  eventAttendeeUpdate: SelfServiceAPI.EventAttendee;
};

// Action Creators
export const updateBookingStatus =
  (locationId: number,
   event: SelfServiceAPI.Event,
   status: BookingStatus,
   cbCompleted: (success: boolean) => void,
  ) => (dispatch: Dispatch<any>) => {
    const eventId = event.id;
    const eventAttendee: SelfServiceAPI.EventAttendee = {
      startDateTime: event.startTime,
      attendeeStatus: status
    };

    dispatch(appSuccessMessage());
    dispatch(appErrorMessage());

    dispatch(bookingStatusUpdateRequest(eventAttendee));
    return patchAccountEventAttendee(locationId, eventId, eventAttendee)
    .then(() => {
      dispatch(bookingStatusUpdateSuccess(eventAttendee));
      dispatch(requestActiveAccountMetadata(event.locationId));
      cbCompleted(true);
    })
    .then(() => {
      fetchEventByEventId({
        eventId: event.id,
        locationId: event.locationId,
        startDatetime: event.startTime,
      })
      .then(((event: SelfServiceAPI.Event) => {
        dispatch(updateScheduleEvent(event));
        dispatch({
          type: BookingUiActionType.UpdateEvent,
          event,
        });
      }))
      .catch((error) => {
        // This is not a critical error, swallow it
        console.error('Cannot fetch to update Event on ui', error);
      });
    })
    .catch(error => {
      switch (error.response.status) {
        case 400:
          dispatch(appErrorMessage('Session is already booked')); // todo: 400 doesn't mean only this error
          break;
        default:
          dispatch(appErrorMessage('Network error, please retry.'));
      }
      dispatch(bookingStatusUpdateFailed());
      console.error('Cannot patchAccountEventAttendee', error);
      cbCompleted(false);
    });
  };

export const bookingStatusUpdateRequest = (eventAttendeeUpdate: SelfServiceAPI.EventAttendee) => ({
  eventAttendeeUpdate,
  type: BookingUiActionType.UpdateBookingStatusRequest
});

export const bookingStatusUpdateSuccess = (eventAttendeeUpdate: SelfServiceAPI.EventAttendee) => ({
  eventAttendeeUpdate,
  type: BookingUiActionType.UpdateBookingStatusSuccess,
});

export const bookingStatusUpdateFailed = () => ({
  type: BookingUiActionType.UpdateBookingStatusFailed,
});

export const cancelScheduledSession =
  (locationId: number, event: SelfServiceAPI.Event, onSuccess: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(appSuccessMessage());
    dispatch(appErrorMessage());
    dispatch({ type: BookingUiActionType.FetchEventRequest });

    cancelScheduledEvent(locationId, event)
    .then(() => {
      dispatch({ type: BookingUiActionType.CancelEventSuccess });
      dispatch(appSuccessMessage('Your session was successfully cancelled.'));
      onSuccess();
    })
    .then(() => {
      fetchEventByEventId({
        eventId: event.id,
        locationId: event.locationId,
        startDatetime: event.startTime,
      })
      .then(((event: SelfServiceAPI.Event) => {
        dispatch(updateScheduleEvent(event));
        dispatch({
          type: BookingUiActionType.UpdateEvent,
          event,
        });
      }))
      .catch((error) => {
        // This is not a critical error, swallow it
        console.error('Cannot fetch to update Event on ui', error);
      });
    })
    .catch((error: any) => {
      dispatch({ type: BookingUiActionType.CancelEventError });
      dispatch(appErrorMessage('Session cancellation failed. Please try again.'));
      console.error('Cannot cancel event', error);
    });
  };

export const fetchEvent = (locationId: number, eventId: number, startDatetime: string) => (dispatch: Dispatch<any>) => {
  dispatch(appSuccessMessage());
  dispatch(appErrorMessage());
  dispatch({ type: BookingUiActionType.FetchEventRequest });

  const eventsRequest: SelfServiceAPI.EventRequest = {
    locationId,
    eventId,
    startDatetime,
  };

  fetchEventByEventId(eventsRequest).then(((event: SelfServiceAPI.Event) => {
    dispatch(receiveEventSuccess(event));
  })).catch((error) => {
    dispatch(receiveEventError());
    console.error('Cannot fetch event', error);
  });
};

export const receiveEventSuccess = (event: SelfServiceAPI.Event) => ({
  event,
  type: BookingUiActionType.FetchEventSuccess
});

export const receiveEventError = () => ({
  type: BookingUiActionType.FetchEventError,
});

export interface BookingUiState {
  isUpdatingBookingStatus: boolean;
  isFetchingEvent: boolean;
  event?: SelfServiceAPI.Event;
}

export const bookingUiInitialState: BookingUiState = {
  isUpdatingBookingStatus: false,
  isFetchingEvent: false
};

export const bookingUiReducer: Reducer<BookingUiState> =
  (state: BookingUiState = bookingUiInitialState, action: Action & BookingUiAction) => {
    switch (action.type) {
      case BookingUiActionType.UpdateBookingStatusRequest:
        return {
          ...state,
          isUpdatingBookingStatus: true,
        };
      case BookingUiActionType.UpdateBookingStatusSuccess:
        return {
          ...state,
          isUpdatingBookingStatus: false,
        };
      case BookingUiActionType.UpdateBookingStatusFailed:
        return {
          ...state,
          isUpdatingBookingStatus: false,
        };
      case BookingUiActionType.FetchEventRequest:
        return {
          ...state,
          isFetchingEvent: true,
        };
      case BookingUiActionType.FetchEventSuccess:
        return {
          ...state,
          isFetchingEvent: false,
          event: action.event,
        };
      case BookingUiActionType.FetchEventError:
        return {
          ...state,
          isFetchingEvent: false,
          event: undefined,
        };
      case BookingUiActionType.UpdateEvent:
        return {
          ...state,
          event: action.event,
        };
      case BookingUiActionType.CancelEventRequest:
        return {
          ...state,
          isFetchingEvent: true,
        };
      case BookingUiActionType.CancelEventSuccess:
        return {
          ...state,
          isFetchingEvent: false,
        };
      case BookingUiActionType.CancelEventError:
        return {
          ...state,
          isFetchingEvent: false,
        };
      default:
        return state;
    }
  };

export default bookingUiReducer;
