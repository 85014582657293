import { Action, Dispatch, Reducer } from 'redux';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';
import { fetchActiveAccountMetadata } from '../data/selfServiceApi';
import {
  groupAccountMemberServices,
  GroupedMemberServices,
  reduceBookableUnits
} from '../utils/memberServicesUtils';

// Action types
enum AccountMetadataActionType {
  Fetch = 'accountMetadata_FETCH',
  Receive = 'accountMetadata_RECEIVE',
  FetchFailed = 'accountMetadata_FETCH_FAILED',
}

// State
export interface AccountMetadataState {
  isFetching: boolean;
  totalBookableUnits: number;
  memberServices: GroupedMemberServices;
  scheduledSessions: SelfServiceAPI.AccountScheduledSession[];
  error?: string;
}

// Action
export type AccountMetadataAction = {
  type: AccountMetadataActionType;
  data: Partial<AccountMetadataState>
};

// Action Creators
export const requestActiveAccountMetadata = (locationId: number) => (dispatch: Dispatch<any>) => {
  dispatch({ type: AccountMetadataActionType.Fetch });
  return fetchActiveAccountMetadata(locationId)
    .then((accountMetadata: SelfServiceAPI.AccountMetadata) => {
      dispatch(receiveActiveAccountMetadata(accountMetadata));
    }).catch((error: any) => {
      console.error(error);
      dispatch({
        type: AccountMetadataActionType.FetchFailed
      });
    });
};

export const receiveActiveAccountMetadata = (accountMetadata: SelfServiceAPI.AccountMetadata) => {
  const groupedMemberServices = groupAccountMemberServices(accountMetadata.memberServices);
  const totalBookableUnits = reduceBookableUnits(groupedMemberServices);
  return {
    type: AccountMetadataActionType.Receive,
    data: {
      totalBookableUnits,
      memberServices: groupedMemberServices,
      scheduledSessions: accountMetadata.scheduledSessions
    }
  };
};

// Reducer
export const accountMetadataInitialState: AccountMetadataState = {
  isFetching: false,
  totalBookableUnits: 0,
  memberServices: {},
  scheduledSessions: []
};

const accountMetadataReducer: Reducer<AccountMetadataState> =
  (state: AccountMetadataState = accountMetadataInitialState, action: Action & AccountMetadataAction) => {
    switch (action.type) {
      case AccountMetadataActionType.Fetch:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };
      case AccountMetadataActionType.Receive:
        return {
          ...state,
          isFetching: false,
          totalBookableUnits: action.data.totalBookableUnits || 0,
          memberServices: action.data.memberServices || {},
          scheduledSessions: action.data.scheduledSessions || [],
          error: undefined
        };
      case AccountMetadataActionType.FetchFailed:
        return {
          ...state,
          isFetching: false,
          error: 'Unable to get member services at this time'
        };
      default:
        return state;
    }
  };

export default accountMetadataReducer;
