import * as SelfServiceAPI from '../typings/SelfServiceAPI';

export type MemberService = {
  name: string;
  bookableUnits: number;
};

export type GroupedMemberServices = {
  [memberServiceId: number]: MemberService
};

export const groupAccountMemberServices = (accountMemberServices: SelfServiceAPI.AccountMemberService[]) => {
  return accountMemberServices.reduce((accumulator: GroupedMemberServices, accountMemberService) => {
    const key = accountMemberService.memberServiceId;
    if (!accumulator[key]) {
      accumulator[key] = {
        name: accountMemberService.memberServiceName,
        bookableUnits: accountMemberService.bookableUnits
      };
    } else {
      accumulator[key].bookableUnits += accountMemberService.bookableUnits;
    }
    return accumulator;
  }, {});
};

export const reduceBookableUnits = (groupedMemberService: GroupedMemberServices) => {
  return Object.keys(groupedMemberService).reduce((accumulator: number, id) => {
    return accumulator + groupedMemberService[id].bookableUnits;
  }, 0);
};
