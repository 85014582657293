import * as React from 'react';
import * as moment from 'moment';
import { BasicType, Box, Row, Col, Button, ListGroupItem, Flex } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Image from './Image';
import appHistory from '../../services/appHistory';
import { getStaffProfileUrl } from '../../data/selfServiceApi';

export interface ScheduleEventItemProps {
  event: SelfServiceAPI.Event;
  isBooked: boolean;
}

const EVENT_ITEM_FORMAT = 'h:mm A';

export const determineShowCallToAction = (startTime: string, status: any): boolean => {
  return !(['Canceled', 'Re-scheduled'].indexOf(status) >= 0) && moment(startTime).isAfter(moment());
};

export const renderEventNameDisplay = (eventName: string | undefined, eventStatus: any): JSX.Element => {
  switch (eventStatus) {
    case 'Canceled':
      return <strong style={{ color: '#b84848' }}><del>{eventName}</del> (Cancelled)</strong>;
    case 'Re-scheduled':
      return <strong style={{ color: '#b84848' }}><del>{eventName}</del> (Cancelled)</strong>;
    default:
      return <strong>{eventName}</strong>;
  }
};

export const renderOpenSlotsDisplay = (openSlots: number | null, maxSlots: number | null): string => {
  return openSlots !== null && maxSlots !== null ? `${openSlots} spot${openSlots > 1 ? 's' : ''} left` : '';
};

export const renderBookButtonDisplay = (full: boolean, isBooked: boolean): JSX.Element | string => {
  let display: string | JSX.Element = 'Book';
  if (isBooked) {
    display = <FontAwesomeIcon icon={faCheck}/>;
  } else if (full) {
    display = 'Full';
  }
  return display;
};

export const renderProfileIcon = (staffId: number, staffProfilePictureHash: string): JSX.Element => {
  const fallback: JSX.Element = (
    <FontAwesomeIcon
      icon={faUserCircle}
      style={{ color: '#bbbbbb', marginRight: '8px' }}
    />
  );
  return (
    <Image
      src={getStaffProfileUrl(staffId, staffProfilePictureHash)}
      fallback={fallback}
      style={{
        position: 'relative',
        top: '2px',
        height: '16px',
        marginRight: '8px',
        borderRadius: '10px',
      }}
    />
  );
};

class ScheduleEventItem extends React.PureComponent<ScheduleEventItemProps, {}> {
  constructor(props: ScheduleEventItemProps) {
    super(props);
    this.handleBookButtonClick = this.handleBookButtonClick.bind(this);
  }

  private handleBookButtonClick(): void {
    const {
      event: {
        id,
        startTime,
      },
      isBooked,
    } = this.props;
    if (isBooked) return;

    appHistory.push(`bookings?eventId=${id}&startTimeStartAt=${startTime}`);
  }

  render() {
    const {
      event: {
        startTime,
        endTime,
        subject,
        eventStatus,
        staffFirstName,
        staffLastName,
        openSlots,
        maxAttendees,
        staffId,
        staffProfilePictureHash,
      },
      isBooked,
    } = this.props;

    const staffName: string = `${staffFirstName} ${staffLastName}`.trim();

    const showCallToAction: boolean = determineShowCallToAction(startTime, eventStatus);
    const eventText: JSX.Element = renderEventNameDisplay(subject, eventStatus);
    const openSlotsDisplayValue: string = renderOpenSlotsDisplay(openSlots, maxAttendees);
    const formattedStartTime: string = moment(startTime).format(EVENT_ITEM_FORMAT);
    const formattedEndTime: string = moment(endTime).format(EVENT_ITEM_FORMAT);
    const full: boolean = openSlots === 0;
    const bookChildren: JSX.Element | string = renderBookButtonDisplay(full, isBooked);
    const instructorIcon: JSX.Element = renderProfileIcon(staffId, staffProfilePictureHash);

    return (
      <ListGroupItem disabled={!showCallToAction || full}>
        <Row className="align-items-center">
          <Col>
            <Row noGutters>
              <Col md="6" xs="12">
                <Flex alignItems="center" style={{ height: '100%' }}>
                  <span>{formattedStartTime} &ndash; {formattedEndTime}</span>
                </Flex>
              </Col>
              <Col md="6" xs="12">
                <Box xs={{ py: 1 }} md={{ py: 0 }}>
                  <Flex alignItems="center" style={{ height: '100%' }}>
                    <BasicType xs={{ sizePx: 16 }} md={{ sizePx: 14 }}>{eventText}</BasicType>
                  </Flex>
                </Box>
              </Col>
            </Row>
          </Col>
          <Col xs={{ size: 12, order: 12 }} md={{ size: 4, order: 1 }}>
            <Row noGutters>
              <Col xs={6} sm={12}>
                <Flex alignItems="center" style={{ height: '100%' }}>
                  {instructorIcon}{staffName}
                </Flex>
              </Col>
              <Col xs={6} sm={12}>
                <Box pr={2} style={{ whiteSpace: 'nowrap' }}>
                  {openSlotsDisplayValue}
                </Box>
              </Col>
            </Row>
          </Col>
          <Col md={{ size: 'auto', order: 12 }} xs={{ size: 'auto', order: 1 }} style={{ width: '150px' }}>
            <Button
              style={
                showCallToAction
                  ? { cursor: (isBooked || full) && 'default' || 'pointer' }
                  : { visibility: 'hidden' }
              }
              large
              stretch
              role="link"
              disabled={full}
              ghost={isBooked}
              onClick={this.handleBookButtonClick}
            >
              {bookChildren}
            </Button>
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}

export default ScheduleEventItem;
