import { Action, Reducer } from 'redux';

// Action types
export enum AuthorizedUserActionType {
  UpdateAuthorizedUser = 'authorize_UPDATE_AUTHORIZED_USER'
}

export type AuthorizedUserAction = {
  type: AuthorizedUserActionType;
  authorizedUser: AuthorizedUserState
};

// Action Creators
export const updateAuthorizedUser = (authorizedUser: AuthorizedUserState) => ({
  type: AuthorizedUserActionType.UpdateAuthorizedUser,
  authorizedUser: { ...authorizedUser }
});

export interface AuthorizedUserState {
  authorized: boolean;
  usersName: string;
  usersProfilePicHash: string | null;
}

export const authorizedUserInitialState: AuthorizedUserState = {
  authorized: false,
  usersName: '',
  usersProfilePicHash: null
};

export const authorizedUserDefaultState = authorizedUserInitialState;

// Reducer
export const authorizedUserReducer: Reducer<AuthorizedUserState> =
  (state = authorizedUserInitialState, action: Action & AuthorizedUserAction) => {
    switch (action.type) {
      case AuthorizedUserActionType.UpdateAuthorizedUser:
        return {
          ...action.authorizedUser
        };
      default:
        return state;
    }
  };

export default authorizedUserReducer;
