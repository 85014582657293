import * as React from 'react';
import { connect } from 'react-redux';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { Error } from '../utility/withFormValidation';
import { Box, Button, Toast, ToastVariant } from '@mycelium/core';
import CreateAccountForm from '../presentation/CreateAccountForm';
import Title from './Title';
import {
  AccountState,
  attemptAccountVerification,
  resetAccountVerification, verifyAccountReset
} from '../../ducks/accountDuck';

const styles: {
  loginCard: string,
  loginContainer: string,
  heading1: string,
  heading2: string,
  borderedLoginCard: string,
  white: string,
  strong: string
} = require('../../styles/login.module.css');

interface CreateAccountContainerProps {
  dispatch?: any;
  locationId: number;
  accountState: AccountState;
  history?: any;
}

interface CreateAccountContainerState {
  errors: Error[];
  email: string;
}

class CreateAccountContainer extends React.Component<CreateAccountContainerProps, CreateAccountContainerState> {
  constructor(props: CreateAccountContainerProps) {
    super(props);
    this.state = {
      errors: [],
      email: ''
    };
    this.navigateToLogin = this.navigateToLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processErrors = this.processErrors.bind(this);
    this.resetVerifyAccount = this.resetVerifyAccount.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
  }

  handleSubmit(errors: Error[], isResubmit: boolean = false) {
    this.setState({ errors });
    if (errors.length === 0) {
      const { dispatch, locationId } = this.props;
      const { email } = this.state;
      dispatch(attemptAccountVerification(email, locationId, isResubmit));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetAccountVerification());
  }

  navigateToLogin(e: any) {
    e.preventDefault();
    const { history, locationId } = this.props;
    history.push(`/locations/${locationId}/login`);
  }

  resetVerifyAccount() {
    const { dispatch } = this.props;
    dispatch(verifyAccountReset());
  }

  updateEmail(email: string) {
    this.setState({ ...this.state, email });
  }

  processErrors() {
    let focused = false;
    return this.state.errors.map((error: any, i: any) => {
      if (!focused && error.ref && error.ref.current) {
        error.ref.current.focus();
        focused = true;
      }
      return (
        <li key={i}>{error.message}</li>
      );
    });
  }

  private renderValidationErrors(): JSX.Element | null {
    const { accountState: { verifyFailed } } = this.props;
    const { errors } = this.state;

    if (((errors.length > 0) || verifyFailed)) {
      return (
        <React.Fragment>
          {!verifyFailed && <React.Fragment>
            <p>The following fields are required:</p>
            <ul>{this.processErrors()}</ul>
          </React.Fragment>}
          {verifyFailed && <div>
            <p>There was an issue with our server, please try again.</p>
          </div>}
        </React.Fragment>
      );
    }

    return null;
  }

  public render() {
    const {
      accountState: {
        emailSent,
        resubmitSuccess,
      }
    } = this.props;
    const {
      email,
    } = this.state;

    return (
      <div className={styles.loginContainer}>
        <Title>Create account</Title>
        <Box p={5} className={styles.loginCard}>
          {!emailSent && <section>
            <CreateAccountForm
              navigateToLogin={this.navigateToLogin}
              afterValidate={(errors: Error[]) => this.handleSubmit(errors)}
              resetVerifyAccount={this.resetVerifyAccount}
              updateEmail={this.updateEmail}
              validationErrors={this.renderValidationErrors()}
            />
          </section>}
          {emailSent && <section>
            <Box mb={4}>
              <h1 className={styles.heading1}>Create Account</h1>
            </Box>
            <Box mb={5}>
              <h2 className={styles.heading2}>Check Your Inbox</h2>
            </Box>
            <Box p={5} className={styles.borderedLoginCard}>
              <Box mb={4}>
                <p className={styles.white}>
                  We sent an email to <span className={styles.strong}>{email}</span> so that you can finish creating your account.
                </p>
              </Box>
              <Button large inverse type="submit" onClick={() => this.handleSubmit(this.state.errors, true)}>
                Resend Email
              </Button>
              {resubmitSuccess && <Box mt={4}>
                <Toast variant={ToastVariant.Success}>
                  <p>Email re-sent. Check your inbox.</p>
                </Toast>
              </Box>}
            </Box>
          </section>}
        </Box>
      </div>
    );
  }
}

interface StateProps {
  accountState: AccountState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    accountState: state.accountReducer
  };
};

const createAccountContainerWithConnect = connect<StateProps, any, {}>(mapStateToProps)(CreateAccountContainer);

export default createAccountContainerWithConnect;
