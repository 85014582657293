import * as React from 'react';
import { connect } from 'react-redux';
import { AppState, fetchLocationInformation } from '../../ducks/appDuck';
import LocationNavigation from '../presentation/LocationNavigation';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import {
  Box,
  Loader,
  Row,
  Col,
  Container,
  ResponsiveLayout,
  ResponsiveContent,
  Toast,
  ToastVariant,
} from '@mycelium/core';
import AccountDrawerContainer from '../containers/AccountDrawerContainer';
import { AuthorizedUserState } from '../../ducks/authorizedUserDuck';
import { displayLoginBodyStyles, resetToDefaultBodyStyles } from '../../utils/styleHelpers';
import LocationSwitch from '../routing/LocationSwitch';

export interface AppLayoutProps {
  match?: any;
  dispatch?: any;
  appState: AppState;
  authorizedUserState: AuthorizedUserState;
  history?: any;
  location?: any;
  successMessage: string;
  errorMessage: string;
}

export class AppLayout extends React.Component<AppLayoutProps, object> {

  constructor(props: AppLayoutProps) {
    super(props);
  }

  componentDidMount() {
    const { dispatch, match: { params } } = this.props;
    dispatch(fetchLocationInformation(params.locationId));
  }

  isAccountRoute(path: string): boolean {
    const parts = path.toLowerCase().split('/');
    const accountRoutes = ['login', 'create-account', 'registration'];
    return accountRoutes.indexOf(parts[parts.length - 1]) > -1;
  }

  private renderMessages(): JSX.Element {
    const { successMessage, errorMessage } = this.props;
    return (
      <React.Fragment>
        {!!successMessage && (
          <Box my={4}>
            <Toast variant={ToastVariant.Success}>
              <p>{successMessage}</p>
            </Toast>
          </Box>
        )}
        {!!errorMessage && (
          <Box my={4}>
            <Toast variant={ToastVariant.Danger}>
              <p>{errorMessage}</p>
            </Toast>
          </Box>
        )}
      </React.Fragment>
    );
  }

  public render() {
    const { locationInfo } = this.props.appState;

    const accountDrawerProps = {
      locationId: this.props.match.params.locationId,
      authorized: this.props.authorizedUserState.authorized,
      usersName: this.props.authorizedUserState.usersName,
      history: this.props.history
    };

    if (!locationInfo) return <Loader />;
    const { location: { pathname } } = this.props;
    this.isAccountRoute(pathname) ? displayLoginBodyStyles() : resetToDefaultBodyStyles();
    return (
      <main id="location-layout">
        <LocationNavigation locationInfo={locationInfo} />
        <ResponsiveLayout role="main">
          <Container fluid id="primary-layout">
            <Box md={{ mx: 3 }}>
              <ResponsiveContent>
                {this.renderMessages()}
                <Row>
                  <Col md={12} noPaddingMobile>
                    <LocationSwitch locationInfo={locationInfo}/>
                  </Col>
                </Row>
              </ResponsiveContent>
            </Box>
          </Container>
          {!this.isAccountRoute(pathname) && (
            <AccountDrawerContainer { ...accountDrawerProps }/>
          )}
        </ResponsiveLayout>
      </main>
    );
  }
}

interface StateProps {
  appState: AppState;
  authorizedUserState: AuthorizedUserState;
  successMessage: string;
  errorMessage: string;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    appState: { ...state.appReducer },
    authorizedUserState: { ...state.authorizedUserReducer },
    successMessage: state.appReducer.successMessage,
    errorMessage: state.appReducer.errorMessage,
  };
};

const appLayoutWithConnect: React.ComponentClass<AppLayoutProps>
  = connect<StateProps, any, AppLayoutProps>(mapStateToProps)(AppLayout);

export default appLayoutWithConnect;
