import * as ReactGA from 'react-ga';

// Minimal analytics implementation. Consider migrating to Redux middleware to capture events.
export const initialize = (trackingCode: string) => {
  const options = typeof jest !== 'undefined' ? { testMode: true } : { };
  ReactGA.initialize(trackingCode, options);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const pageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};
