class ScrollService {
  public scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  public scrollToBottom(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  public scrollToLastPoint(key: string): void {
    const scrollToY: number = Number(sessionStorage.getItem(this.getKey(key)) || '0');
    window.scrollTo(0, scrollToY);
  }

  public saveScrollPoint(key: string): void {
    sessionStorage.setItem(this.getKey(key), window.pageYOffset.toString());
  }

  private getKey(key: string): string {
    return `scrollService--${key}`;
  }
}

export const scrollService = new ScrollService();
