import * as Promise from 'bluebird';
import { BasicValidator, OK } from './validators';

const regExpNumber = /[0-9]/;

const regExpCapital = /[A-Z]/;

const regExpLower = /[a-z]/;

export interface SelfServicePasswordValidation {
  length: boolean;
  number: boolean;
  capital: boolean;
  lower: boolean;
  isValid?: boolean;
}

export const getSelfServicePasswordValidation = (password: string): SelfServicePasswordValidation => {
  const output: SelfServicePasswordValidation = {
    length: password.length >= 8 && password.length <= 256,
    number: regExpNumber.test(password),
    capital: regExpCapital.test(password),
    lower: regExpLower.test(password),
  };

  output.isValid = Object.keys(output).reduce((acc: boolean, key: string) => acc && output[key], true);

  return output;
};

const selfServicePasswordValidator: BasicValidator<HTMLInputElement> = (ref, error) => {
  const password: string = !!ref.current && ref.current.value || '';

  return (
    getSelfServicePasswordValidation(password).isValid
      ? Promise.resolve(OK)
      : Promise.reject(error)
  );
};

export default selfServicePasswordValidator;
