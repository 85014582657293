import * as React from 'react';
import { connect } from 'react-redux';
import { Box, formatPhoneNumber } from '@mycelium/core';
import * as qs from 'qs';
import { attemptLoginUser, loginReset, LoginState } from '../../ducks/loginDuck';
import Login from '../Login';
import Title from './Title';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { Error } from '../utility/withFormValidation';
import { RETURN_URL_PARAM } from '../../constants/constants';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import { scrollService } from '../utility/scrollService';

const styles: {
  loginCard: string,
  loginContainer: string,
  heading1: string,
  heading2: string
} = require('../../styles/login.module.css');

export interface LoginContainerProps {
  locationInfo: SelfServiceAPI.Location;
  dispatch?: any;
  loginState: LoginState;
  history?: any;
}

interface LoginContainerState {
  errors: Error[];
  email: string;
  password: string;
  initReturnUrl?: string;
}

export class LoginContainer extends React.Component<LoginContainerProps, LoginContainerState> {
  constructor(props: LoginContainerProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processErrors = this.processErrors.bind(this);
    this.resetFailedLogin = this.resetFailedLogin.bind(this);
    this.updateEmailAndPassword = this.updateEmailAndPassword.bind(this);
    this.state = {
      errors: [],
      email: '',
      password: '',
      initReturnUrl: qs.parse(location.search, { ignoreQueryPrefix: true })[RETURN_URL_PARAM]
    };
  }

  public componentDidMount(): void {
    scrollService.scrollToTop();
  }

  private updateEmailAndPassword(email: string, password: string): void {
    this.setState({ ...this.state, email, password });
  }

  private handleSubmit(errors: Error[]): void {
    this.setState({ errors });
    if (errors.length === 0) {
      const { dispatch, history, locationInfo: { locationId } } = this.props;
      const { email, password, initReturnUrl } = this.state;
      dispatch(attemptLoginUser(locationId, email, password, history, initReturnUrl));
    }
  }

  private processErrors(): JSX.Element[] {
    let focused = false;
    return this.state.errors.map((error: any, i: any) => {
      if (!focused && error.ref && error.ref.current) {
        error.ref.current.focus();
        focused = true;
      }
      return (
        <li key={i}>{error.message}</li>
      );
    });
  }

  private resetFailedLogin(): void {
    const { dispatch } = this.props;
    dispatch(loginReset());
  }

  private renderValidationErrors(): JSX.Element | null {
    const {
      locationInfo: {
        clubName,
        locationName,
        locationPhoneNumber,
      },
      loginState: {
        loginFailed,
        networkError,
      },
    } = this.props;

    if (this.state.errors.length > 0) {
      return (
        <React.Fragment>
          <p>The following fields are required:</p>
          <ul>{this.processErrors()}</ul>
        </React.Fragment>
      );
    }

    if (loginFailed) {
      return (
        <p>
          The email or password was not valid.
          Try again or call {clubName} {locationName} at <a
          href={`tel:${locationPhoneNumber}`}>{formatPhoneNumber(locationPhoneNumber)}</a> for assistance.
        </p>
      );
    }

    if (networkError) {
      return (
        <p>There was an issue with our server, please try again.</p>
      );
    }

    return null;
  }

  public render() {
    const {
      locationInfo,
      locationInfo: {
        locationName,
      },
    } = this.props;

    return (
      <div className={styles.loginContainer}>
        <Title>Login</Title>
        <Box p={5} className={styles.loginCard}>
          <Box mb={4}>
            <h1 className={styles.heading1}>Welcome to {locationName}</h1>
          </Box>
          <Box mb={5}>
            <h2 className={styles.heading2}>Log in with Club OS</h2>
          </Box>
          <Login
            locationInfo={locationInfo}
            afterValidate={(errors: Error[]) => this.handleSubmit(errors)}
            resetFailedLogin={this.resetFailedLogin}
            validationErrors={this.renderValidationErrors()}
            updateEmailAndPassword={this.updateEmailAndPassword}
          />
        </Box>
      </div>
    );
  }
}

interface StateProps {
  locationInfo: SelfServiceAPI.Location;
  loginState: LoginState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    locationInfo: state.appReducer.locationInfo,
    loginState: state.loginReducer
  };
};

const loginContainerWithConnect = connect<StateProps, any, {}>(mapStateToProps)(LoginContainer);

export default loginContainerWithConnect;
