import * as React from 'react';
import * as qs from 'qs';
import Helmet from 'react-helmet';
import { Box } from '@mycelium/core';

interface ErrorPage404Props {
  locationSearch?: string;
}

const ErrorPage404: React.SFC<ErrorPage404Props> = ({ locationSearch }) => {
  const path =
    locationSearch
      ? qs.parse(locationSearch, { ignoreQueryPrefix: true }).path || ''
      : '';
  return (
    <React.Fragment>
      <Helmet><title>Page not found</title></Helmet>
      <Box m="4">
        <h1 style={{ fontSize: '3em' }}>404 Page not found</h1>
        <p>The requested path {path || ''} does not exist</p>
      </Box>
    </React.Fragment>
  );
};

export default ErrorPage404;
