import * as React from 'react';
import { connect } from 'react-redux';
import { Box, ListGroup, ListGroupItem, Button, Loader, Toast, ToastVariant } from '@mycelium/core';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import { fetchSchedule } from '../../ducks/scheduleDuck';
import { resolveBatchDateRange, groupOrderedEventsByDate, EventsByDate } from '../../utils/eventsUtil';
import ScheduleItems from '../presentation/ScheduleItems';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import { DEFAULT_EVENT_TYPES } from '../../constants/constants';
import Title from './Title';
import { scrollService } from '../utility/scrollService';
import { DetectScrollBottom } from '../utility/DetectScrollBottom';

export interface ScheduleContainerProps {
  locationId: number;
}

export interface ScheduleComponentDataProps {
  isFetching: boolean;
  hasMore: boolean;
  eventsCount: number;
  eventsByDate: EventsByDate;
  lastEventsRequest?: SelfServiceAPI.EventsRequest;
  accountScheduledSessionIds: number[];
}

export interface ScheduleComponentState {
  isLastLoadSuccess?: boolean;
}

export interface ScheduleComponentEventsProps {
  onLoad: (lastEventsRequest: SelfServiceAPI.EventsRequest | undefined, onLoad: (success: boolean) => void) => void;
}

export interface ScheduleComponentProps extends ScheduleComponentDataProps, ScheduleComponentEventsProps {
}

const MIN_INITIAL_EVENTS: number = 8;

// Leave as a container as it will eventually support filtering
export class ScheduleComponent extends React.Component<ScheduleComponentProps, ScheduleComponentState> {
  private detectScrollBottom: DetectScrollBottom;

  constructor(props: ScheduleComponentProps) {
    super(props);

    this.state = {
      isLastLoadSuccess: true,
    };

    this.load = this.load.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleRetryLoad = this.handleRetryLoad.bind(this);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
  }

  static getDerivedStateFromProps(nextProps: ScheduleComponentProps, prevState: ScheduleComponentState): ScheduleComponentState {
    if (!prevState.isLastLoadSuccess) {
      setTimeout(() => {  // push it next circle since the error toast is not rendered
        scrollService.scrollToBottom();
      }, 0);
    }
    return {};
  }

  private readonly scrollServiceKey: string = 'ScheduleComponent-scroll-last-point';

  public componentDidMount(): void {
    const { eventsCount, hasMore } = this.props;
    if (!eventsCount && hasMore) {
      scrollService.scrollToTop();
      this.load();
    } else {
      scrollService.scrollToLastPoint(this.scrollServiceKey);
    }

    this.detectScrollBottom = new DetectScrollBottom({
      bottomThreshold: window.innerHeight * 0.20,
      onScrollBottom: this.handleScrollToBottom,
    });
  }

  public componentWillUnmount(): void {
    this.detectScrollBottom.dispose();
  }

  private handleScrollToBottom(): void {
    const { isFetching, hasMore } = this.props;
    if (
      this.state.isLastLoadSuccess &&
      !isFetching &&
      hasMore
    ) {
      this.load();
    }
  }

  private load(): void {
    const { lastEventsRequest, onLoad } = this.props;
    onLoad(lastEventsRequest, this.handleLoad);
  }

  private handleLoad(success: boolean): void {
    const { isFetching, hasMore, eventsCount } = this.props;
    this.setState({ isLastLoadSuccess: success });
    if (
      success &&
      !isFetching &&
      hasMore &&
      eventsCount < MIN_INITIAL_EVENTS
    ) {
      this.load();
    }
  }

  private handleRetryLoad(): void {
    this.load();
  }

  public render() {
    const {
      isFetching,
      eventsCount,
      eventsByDate,
      hasMore,
      accountScheduledSessionIds,
    } = this.props;

    return (
      <ListGroup>
        <Title>Schedule</Title>
        {!isFetching && !hasMore && !eventsCount && (
          <ListGroupItem>Empty</ListGroupItem>
        )}
        <ScheduleItems
          eventsByDate={eventsByDate}
          accountScheduledSessionIds={accountScheduledSessionIds}
        />
        {!isFetching && !hasMore && !!eventsCount && (
          <ListGroupItem>End of List</ListGroupItem>
        )}
        {!isFetching && hasMore && !this.state.isLastLoadSuccess && (
          <Box my="4" textAlign="center">
            <Box mb={4}>
              <Toast variant={ToastVariant.Danger}>
                <p>Oops! We could not display the information you requested. Please try again.</p>
              </Toast>
            </Box>
            <Button onClick={this.handleRetryLoad}>Load more</Button>
          </Box>
        )}
        {isFetching && (
          <ListGroupItem style={{ textAlign: 'center' }}>
            <Loader />
          </ListGroupItem>
        )}
      </ListGroup>
    );
  }
}

const mapStateToProps = (state: SelfServiceCombinedReducers): ScheduleComponentDataProps => {
  const {
    scheduleReducer: scheduleState,
    accountMetadataReducer: { scheduledSessions },
  } = state;
  return {
    isFetching: scheduleState.isFetching,
    hasMore: scheduleState.hasMore,
    eventsCount: scheduleState.events.length,
    eventsByDate: groupOrderedEventsByDate(scheduleState.events),
    accountScheduledSessionIds: scheduledSessions.map(ss => ss.id),
    lastEventsRequest: scheduleState.lastEventsRequest,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ScheduleContainerProps): ScheduleComponentEventsProps => {
  return {
    onLoad: (lastEventsRequest: SelfServiceAPI.EventsRequest, onLoad: (success: boolean) => void): void => {
      const { locationId } = ownProps;
      const lastBatchEndDate = lastEventsRequest ? lastEventsRequest.startTimeEndAt : null;
      const { startDatetime, endDatetime } = resolveBatchDateRange(lastBatchEndDate);
      const eventsRequest: SelfServiceAPI.EventsRequest = {
        locationId,
        startTimeStartAt: startDatetime,
        startTimeEndAt: endDatetime,
        eventTypeId: DEFAULT_EVENT_TYPES,
      };
      dispatch(fetchSchedule(eventsRequest, onLoad));
    },
  };
};

const ScheduleContainer: React.ComponentClass<ScheduleContainerProps> =
  connect<ScheduleComponentDataProps, ScheduleComponentEventsProps, ScheduleContainerProps>(
    mapStateToProps, mapDispatchToProps)(ScheduleComponent);

export default ScheduleContainer;
