import * as React from 'react';
import { BasicType, Box, ListGroup, ListGroupItem } from '@mycelium/core';
import { GroupedMemberServices, MemberService } from '../../utils/memberServicesUtils';

export interface SessionStatsProps {
  memberServices: GroupedMemberServices;
  displayBookableUnits: string;
}

const SessionStats: React.SFC<SessionStatsProps> = (props) => {
  const { memberServices, displayBookableUnits } = props;
  const memberServiceList: MemberService[] =
    Object.keys(memberServices)
    .map((key: string) => memberServices[key])
    .filter((memberService: MemberService) => !!memberService.bookableUnits);
  return (
    <BasicType textAlign="left">
      <ListGroup>
        <ListGroupItem key="member-service-total">
          <span>Sessions Available:</span>
          <Box dp="inline" pl="2">
            <b>{displayBookableUnits}</b>
          </Box>
        </ListGroupItem>
        {memberServiceList.map((memberService: MemberService, index: number) => (
          <ListGroupItem key={index} compact>
            <Box dp="inline-block" pr="2">
              <b>{memberService.bookableUnits}x</b>
            </Box>
            <span>{memberService.name}</span>
          </ListGroupItem>
        ))}
      </ListGroup>
    </BasicType>
  );
};

export default SessionStats;
