import { Action, Reducer } from 'redux';
import { requestLocationInfoById } from '../data/selfServiceApi';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';

// Action types
enum AppActionType {
  ReceiveLocationInfoSuccess = 'app_RECEIVE_LOCATION_INFO_SUCCESS',
  FetchLocationInfo = 'app_FETCH_LOCATION_INFO',
  ReceiveLocationInfoFailed = 'app_RECEIVE_LOCATION_INFO_FAILED',
  SuccessMessage = 'app_SUCCESS_MESSAGE',
  ErrorMessage = 'app_ERROR_MESSAGE',
}

export interface AppState {
  locationInfo: SelfServiceAPI.Location | any; // TODO: remove any and fix types
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

export type AppAction = {
  type: AppActionType;
  payload: object; // TODO: can I make this more exact?
};

// Action Creators
export const fetchLocationInformation = (locationId: number) => (dispatch: any) => { // TODO: what type is 'dispatch'?
  dispatch({ type: AppActionType.FetchLocationInfo });
  requestLocationInfoById(locationId).then(((data: SelfServiceAPI.Location) => {
    dispatch(receiveLocationInformationSuccess(data));
  }));
};

export const receiveLocationInformationSuccess = (locationInfo: SelfServiceAPI.Location) => ({
  type: AppActionType.ReceiveLocationInfoSuccess,
  payload: { ...locationInfo }
});

export const appSuccessMessage = (message: string = '') => ({
  type: AppActionType.SuccessMessage,
  payload: message,
});

export const appErrorMessage = (message: string = '') => ({
  type: AppActionType.ErrorMessage,
  payload: message,
});

// Reducer
export const appInitialState: AppState = {
  locationInfo: {},
  isFetching: false,
  successMessage: '',
  errorMessage: '',
};

const appReducer: Reducer<AppState> = (state: AppState = appInitialState, action: Action & AppAction) => {
  switch (action.type) {
    case AppActionType.FetchLocationInfo:
      return {
        ...state,
        isFetching: true
      };
    case AppActionType.ReceiveLocationInfoSuccess:
      return {
        ...state,
        locationInfo: action.payload,
        isFetching: false
      };
    case AppActionType.ReceiveLocationInfoFailed:
      return {
        ...state,
        isFetching: false
      };
    case AppActionType.SuccessMessage:
      return {
        ...state,
        successMessage: action.payload as any,
      };
    case AppActionType.ErrorMessage:
      return {
        ...state,
        errorMessage: action.payload as any,
      };
    default:
      return state;
  }
};

export default appReducer;
