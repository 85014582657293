import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Promise from 'bluebird';
import App from './components/App';

import '!style-loader!css-loader!./styles/reset.css';
import '!style-loader!css-loader!./styles/global.css';
import '!style-loader!css-loader!./styles/shame.css';
import '!style-loader!css-loader!./styles/debug.css';
import '!style-loader!css-loader!bootstrap/dist/css/bootstrap-grid.min.css';

// We don't throw errors in all of our promises
Promise.config({ warnings: false });

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement
);
