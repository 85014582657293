import * as moment from 'moment';

interface FormattedDates {
  fullDateLong: string;
  fullDateShort: string;
  startTime: string;
  endTime: string;
  duration: string;
}

export const getDurationInMinutes = (startTime: moment.Moment, endTime: moment.Moment): string => {
  const minutes = endTime.diff(startTime, 'minutes');
  return `${minutes} minutes`;
};

export const getFormattedDates = (startTime: string | moment.Moment, endTime: string | moment.Moment): FormattedDates => {
  const mStartDate = moment(startTime);
  const mEndDate = moment(endTime);
  return {
    fullDateLong: mStartDate.format('dddd, MMMM DD, YYYY'),
    fullDateShort: mStartDate.format('dddd, MMM DD, YYYY'),
    startTime: mStartDate.format('h:mm A'),
    endTime: mEndDate.format('h:mm A'),
    duration: getDurationInMinutes(mStartDate, mEndDate)
  };
};
