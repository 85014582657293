import * as React from 'react';
import { Link } from 'react-router-dom';
import { BasicType, Box, Palette } from '@mycelium/core';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';
import { getFormattedDates } from '../../utils/classDetailsUtil';

interface ScheduledSessionProps {
  locationId: number;
  scheduledSession: SelfServiceAPI.AccountScheduledSession;
}

class ScheduledSession extends React.PureComponent<ScheduledSessionProps> {
  private readonly borderColor: string = `${Palette.lighterGrey} ${Palette.lighterGrey} ${Palette.lighterGrey} ${Palette.blue}`;

  render() {
    const { locationId, scheduledSession } = this.props;
    const { name, id, startTime, endTime } = scheduledSession;
    const formattedDates = getFormattedDates(startTime, endTime);
    return (
      <li>
        <Box p={3} mt={3} bt="1" br="1" bb="1" bl="2" style={{ borderColor: this.borderColor }}>
          <Box>
            <BasicType sizePx={13} style={{ fontWeight: 600 }}>
              <span>{formattedDates.fullDateShort}</span><br/>
              <span>{formattedDates.startTime} - {formattedDates.endTime}</span>
            </BasicType>
          </Box>
          <Box mt={2}>
            <BasicType sizePx={16} style={{ fontWeight: 600 }}>{name}</BasicType>
          </Box>
          <Box mt={2}>
            <Link to={`/locations/${locationId}/manage/${id}/${startTime}`}>
              <Box dp="inline" style={{ cursor: 'pointer' }}>
                <BasicType sizePx={13}>Manage session</BasicType>
              </Box>
            </Link>
          </Box>
        </Box>
      </li>
    );
  }
}

export default ScheduledSession;
