import * as Promise from 'bluebird';
import { RefObject } from 'react';

export const OK = 'ok';
// TODO: Move to @mycelium/validation or some other manageable project
// TODO: Consider a type validator using `validity.typeMismatch` - https://developer.mozilla.org/en-US/docs/Web/API/ValidityState

export type BasicValidator<R> = <T extends any>(ref: RefObject<R>, error: T) => Promise<any>;

// https://github.com/TechniqueSoftware/technique-web/blob/master/src/main/webapp/js/client_app/helpers/validationHelpers.js
export function isEmail(email: string) {

  // tslint:disable-next-line:max-line-length
  const re = new RegExp('^[a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'); // eslint-disable-line
  return re.test(email);
}

export const requiredField: BasicValidator<HTMLInputElement> = (ref, error) => {
  if (ref.current && !ref.current.required) console.warn('Input must have the required attribute');
  const meetsRequiredAndType = ref.current && !ref.current.validity.typeMismatch && ref.current.validity.valid;
  const valueIsPresent = ref.current && !ref.current.validity.valueMissing;
  return meetsRequiredAndType || valueIsPresent ?
    Promise.resolve(OK) : Promise.reject(error);
};

export const emailField: BasicValidator<HTMLInputElement> = (ref, error) => {
  return ref.current && isEmail(ref.current.value) ?
    Promise.resolve(OK) : Promise.reject(error);
};

export const maxLengthField = <T extends any>(ref: RefObject<HTMLInputElement>, maxLength: number, error: T): Promise<any> => {
  return ref.current && ref.current.value.length <= maxLength ?
    Promise.resolve(OK) : Promise.reject(error);
};
