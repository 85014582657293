import { Action, Reducer } from 'redux';
import { postRegistrationAccount } from '../data/selfServiceApi';
import { RegistrationAccount } from '../typings/registration';

// Action types
enum RegistrationActionType {
  RegistrationSubmitSuccess = 'registration_SUBMIT_SUCCESS',
  RegistrationSubmitAttempt = 'registration_SUBMIT_ATTEMPT',
  RegistrationSubmitFailed = 'registration_SUBMIT_FAILED',
  RegistrationReset = 'registration_RESET'
}

export type RegistrationAction = {
  type: RegistrationActionType;
  registered: boolean;
  error: {};
};

// Action Creators
export const attemptToRegisterAccount = (registrationAccount: RegistrationAccount) => (dispatch: any) => {
  dispatch({ type: RegistrationActionType.RegistrationSubmitAttempt });
  postRegistrationAccount(registrationAccount)
    .then(() => dispatch({ type: RegistrationActionType.RegistrationSubmitSuccess }))
    .catch((error) => dispatch({ error, type: RegistrationActionType.RegistrationSubmitFailed }));
};

// Reducer
export interface RegistrationState {
  isFetching: boolean;
  registered: boolean;
  error: {};
}

export const registrationInitialState: RegistrationState = {
  isFetching: false,
  registered: false,
  error: {}
};

const registrationReducer: Reducer<RegistrationState> =
  (state: RegistrationState = registrationInitialState, action: Action & RegistrationAction) => {
    switch (action.type) {
      case RegistrationActionType.RegistrationSubmitAttempt:
        return {
          ...state,
          isFetching: true,
          errors: {}
        };
      case RegistrationActionType.RegistrationSubmitSuccess:
        return {
          ...state,
          isFetching: false,
          registered: true
        };
      case RegistrationActionType.RegistrationSubmitFailed:
        return {
          ...state,
          isFetching: false,
          error: action.error
        };
      case RegistrationActionType.RegistrationReset:
        return {
          ...state,
          ...registrationInitialState
        };
      default:
        return state;
    }
  };

export default registrationReducer;
