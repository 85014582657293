import { Action, Reducer } from 'redux';

// Action types
export enum PermissionsActionType {
  UpdatePermissions = 'permissions_UPDATE_PERMISSIONS'
}

export type PermissionsAction = {
  type: PermissionsActionType;
  permissions: PermissionsState;
};

// Action Creators
export const updatePermissions = (permissions: {}) => ({
  type: PermissionsActionType.UpdatePermissions,
  permissions
});

export interface PermissionsState {
  purchaseBy: number; // time
}

export const permissionsInitialState: PermissionsState = {
  purchaseBy: 0
};

export const permissionsDefaultState = permissionsInitialState;

// Reducer
export const permissionsReducer: Reducer<PermissionsState> = (state = permissionsInitialState, action: Action & PermissionsAction) => {
  switch (action.type) {
    case PermissionsActionType.UpdatePermissions:
      return {
        ...action.permissions
      };
    default:
      return state;
  }
};

export default permissionsReducer;
