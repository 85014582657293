import * as React from 'react';
import { Box, Button, BasicType } from '@mycelium/core';
import { resetAuthorization } from '../../services/authorizationService';
import appHistory from '../../services/appHistory';
import Title from './Title';
import { scrollService } from '../utility/scrollService';

export interface AccountContainerProps {
}

// Leave as a container for future implementations
class AccountContainerComponent extends React.Component<AccountContainerProps, {}> {

  constructor(props: AccountContainerProps) {
    super(props);
    this.handleResetAuthorization = this.handleResetAuthorization.bind(this);
  }

  public componentDidMount(): void {
    scrollService.scrollToTop();
  }

  private handleResetAuthorization():void {
    resetAuthorization(() => {
      appHistory.push('schedule');
    });
  }

  render() {
    return (
      <div className="d-flex justify-content-between">
        <Title>My Account</Title>
        <div className="d-none d-md-block">
          <h1>
            <BasicType size="2">My Account</BasicType>
          </h1>
        </div>
        <Box mw="100" m="3" md={{ mw: 9, m: 0 }} stretch>
          <Button ghost large onClick={this.handleResetAuthorization} stretch>Log Out</Button>
        </Box>
      </div>
    );
  }
}

export default AccountContainerComponent;
