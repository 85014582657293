import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Drawer, Button, Box, Palette } from '@mycelium/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import SessionStats, { SessionStatsProps } from '../presentation/SessionStats';
import ScheduledSessions from '../presentation/ScheduledSessions';
import { AccountMetadataState, requestActiveAccountMetadata } from '../../ducks/accountMetadataDuck';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';

const styles: {
  whiteLink: string,
} = require('../../styles/login.module.css');

interface AccountDrawerContainerContainerProps {
  usersName: string;
  authorized: boolean;
  locationId: number;
}

interface AccountDrawerContainerComponentDataProps {
  usersName: string;
  authorized: boolean;
  locationId: number;
  accountMetadataState: AccountMetadataState;
}

interface AccountDrawerContainerComponentMethodsProps {
  requestActiveAccountData: () => void;
}

export interface AccountDrawerContainerComponentProps
  extends AccountDrawerContainerComponentDataProps, AccountDrawerContainerComponentMethodsProps {
}

export class AccountDrawerComponent extends React.Component<AccountDrawerContainerComponentProps> {
  constructor(props: AccountDrawerContainerComponentProps) {
    super(props);
  }

  public componentDidMount(): void {
    const { authorized, requestActiveAccountData } = this.props;
    if (authorized) {
      requestActiveAccountData();
    }
  }

  render() {
    const { usersName, authorized, locationId, accountMetadataState } = this.props;
    const { memberServices, totalBookableUnits, isFetching } = accountMetadataState;
    const displayBookableUnits = isFetching ? '...' : `${totalBookableUnits}`;

    const sessionStatsProps: SessionStatsProps = {
      memberServices,
      displayBookableUnits
    };

    const userNameStyle: React.CSSProperties = {
      width: '100%',
      maxWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      padding: '0 8px',
      fontSize: '12px',
    };

    const sessionsStyle: React.CSSProperties = {
      whiteSpace: 'nowrap',
      paddingRight: '8px',
      fontSize: '12px',
    };

    return (
      <Drawer p="0">
        {authorized ? (
          <Box p="3">
            <table style={{ width: '100%' }}>
              <tbody>
              <tr>
                <td>
                  <Box style={{ opacity: 0.4 }}>
                    <FontAwesomeIcon icon={faUserCircle}/>
                  </Box>
                </td>
                <td style={userNameStyle}>
                  {usersName}
                </td>
                <td className="d-inline d-md-none" style={sessionsStyle}>
                    ({displayBookableUnits} sessions available)
                </td>
                <td>
                  <Link className={styles.whiteLink} to={`/locations/${locationId}/account`}>
                    <FontAwesomeIcon icon={faCog}/>
                  </Link>
                </td>
              </tr>
              </tbody>
            </table>
            <Box bg={Palette.white} fg={Palette.black} mt="3" borderRound>
              <SessionStats {...sessionStatsProps} />
            </Box>
            {!!accountMetadataState.scheduledSessions.length &&
              <Box bg={Palette.white} fg={Palette.black} mt="3" borderRound>
                <ScheduledSessions
                  locationId={locationId}
                  scheduledSessions={accountMetadataState.scheduledSessions}
                />
              </Box>
            }
          </Box>
        ) : (
          <Box pt="0" md={{ pt: 3 }}
               className="d-flex flex-md-column flex-sm-row align-items-center justify-content-center">
            <Box py="3">First time here?</Box>
            <Box mx="2" md={{ mx: 0 }}>
              <Link to={`/locations/${locationId}/create-account`}>
                <Button ghost inverse>
                  Create Account
                </Button>
              </Link>
            </Box>
            <Box py="3">
              <span>or <Link className={styles.whiteLink} to={`/locations/${locationId}/login`}>Log In</Link></span>
            </Box>
          </Box>
        )}
      </Drawer>
    );
  }
}

const mapStateToProps = (state: SelfServiceCombinedReducers,
                         ownProps: AccountDrawerContainerContainerProps
): AccountDrawerContainerComponentDataProps => {
  return {
    usersName: ownProps.usersName,
    authorized: ownProps.authorized,
    locationId: ownProps.locationId,
    accountMetadataState: state.accountMetadataReducer,
  };
};

const mapDispatchToProps = (dispatch: any,
                            ownProps: AccountDrawerContainerContainerProps
): AccountDrawerContainerComponentMethodsProps => ({
  requestActiveAccountData: () => {
    dispatch(requestActiveAccountMetadata(ownProps.locationId));
  }
});

const AccountDrawerContainer: React.ComponentClass<AccountDrawerContainerContainerProps> =
  connect<AccountDrawerContainerComponentDataProps,
    AccountDrawerContainerComponentMethodsProps,
    AccountDrawerContainerContainerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(AccountDrawerComponent);

export default AccountDrawerContainer;
