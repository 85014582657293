export enum BookingStatus {
  Active = 'A',
  Confirmed = 'CF',
  Canceled = 'CN',
  CanceledCharged= 'CC',
  Rescheduled = 'R',
  NoShow = 'NN',
  NoShowCharge = 'NC',
  Completed = 'CP',
  Deleted = 'D',
}
