import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as Promise from 'bluebird';
import { Box, Flex, TextInput, Button, Palette, Toast, ToastVariant } from '@mycelium/core';
import { attemptToRegisterAccount, RegistrationState } from '../../ducks/registrationDuck';
import { RegistrationAccount, RegistrationToken } from '../../typings/registration';
import { SelfServiceCombinedReducers } from '../../store/selfServiceStore';
import CreateAccountTerms from '../presentation/CreateAccountTerms';
import NewPasswordContainer from './NewPasswordContainer';
import RegistrationComplete from '../presentation/RegistrationComplete';
import PasswordInput from '../presentation/PasswordInput';
import withFormValidation, { WithFormValidationProps } from '../utility/withFormValidation';
import { requiredField } from '../../utils/validators';
import selfServicePasswordValidator from '../../utils/selfServicePasswordValidator';

enum RegistrationFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  NewPassword = 'newPassword',
}

type ValidationError = { text: string, name: RegistrationFormFields };

export interface RegistrationFormContainerProps {
  locationId: number;
  registrationState: RegistrationState;
  dispatch: Dispatch<any>;
  token: RegistrationToken;
  tokenString: string;
}

export interface RegistrationFormContainerWithFormValidationProps extends RegistrationFormContainerProps, WithFormValidationProps {
}

export interface RegistrationFormContainerState {
  [RegistrationFormFields.FirstName]?: string;
  [RegistrationFormFields.LastName]?: string;
  [RegistrationFormFields.NewPassword]: string;
}

export class RegistrationFormContainer extends
  React.Component<RegistrationFormContainerWithFormValidationProps, RegistrationFormContainerState> {

  readonly firstNameText = React.createRef<HTMLInputElement>();
  readonly lastNameText = React.createRef<HTMLInputElement>();
  readonly passwordText = React.createRef<HTMLInputElement>();

  static readonly firstNameTextError = {
    text: 'First Name',
    name: RegistrationFormFields.FirstName
  };
  static readonly lastNameTextError = {
    text: 'Last Name',
    name: RegistrationFormFields.LastName
  };
  static readonly passwordTextError = {
    text: 'Password',
    name: RegistrationFormFields.NewPassword
  };

  static renderValidationErrors(errors: ValidationError[]) {
    return errors.map((error, index) => (
      <li key={index}>{error.text}</li>
    ));
  }

  constructor(props: RegistrationFormContainerWithFormValidationProps) {
    super(props);
    this.afterValidate = this.afterValidate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      [RegistrationFormFields.FirstName]: props.token.firstName,
      [RegistrationFormFields.LastName]: props.token.lastName,
      [RegistrationFormFields.NewPassword]: ''
    };
  }

  componentDidMount() {
    this.props.registerValidators([{
      name: 'firstNameText',
      validator: () => requiredField<ValidationError>(this.firstNameText, RegistrationFormContainer.firstNameTextError)
    }, {
      name: 'lastNameText',
      validator: () => requiredField<ValidationError>(this.lastNameText, RegistrationFormContainer.lastNameTextError)
    }, {
      name: 'passwordText',
      validator: () => Promise.all([
        requiredField<ValidationError>(this.passwordText, RegistrationFormContainer.passwordTextError),
        selfServicePasswordValidator<ValidationError>(this.passwordText, RegistrationFormContainer.passwordTextError)
      ])
    }]);
  }

  afterValidate(errors: ValidationError[]) {
    if (errors.length === 0) {
      const registrationAccount: RegistrationAccount = {
        locationId: this.props.locationId,
        token: this.props.tokenString,
        firstName: this.state[RegistrationFormFields.FirstName] || '',
        lastName: this.state[RegistrationFormFields.LastName] || '',
        password: this.state[RegistrationFormFields.NewPassword] || ''
      };
      this.props.dispatch(attemptToRegisterAccount(registrationAccount));
    } else {
      this.props.errors.some((error) => {
        if (error.ref && error.ref.current) {
          error.ref.current.focus();
          return true;
        }
        return false;
      });
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const nextState = { ...this.state };
    nextState[event.target.name] = event.target.value;
    this.setState(nextState);
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.validate(undefined, this.afterValidate);
  }

  // TODO: Use font system from Mycelium once it's available, and remove inline styles
  render() {
    const textInputBaseProps = {
      frosted: true,
      required: true,
      large: true
    };

    const fieldErrors = this.props.errors.map((error: ValidationError) => error.name);

    return (
      <Box style={{ color: Palette.white }} m="4" mw="11">
        {this.props.registrationState.registered ? (
          <RegistrationComplete />
        ) : (
          <React.Fragment>
            <h3 style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>Create Account</h3>
            <Box mt="2" mb="4">
              <h1 style={{ fontSize: '2em', fontWeight: 400, textAlign: 'center' }}>Complete Profile</h1>
            </Box>
            <form onSubmit={this.handleSubmit} noValidate>
              <Flex>
                <Box flexFill mr="1" mb="2">
                  <TextInput
                    type="text"
                    name={RegistrationFormFields.FirstName}
                    ref={this.firstNameText}
                    placeholder="First Name"
                    disabled={!!this.props.token.firstName}
                    hasError={fieldErrors.indexOf(RegistrationFormFields.FirstName) >= 0}
                    value={this.state[RegistrationFormFields.FirstName]}
                    onChange={this.handleChange}
                    {...textInputBaseProps} />
                </Box>
                <Box flexFill ml="1" mb="2">
                  <TextInput
                    type="text"
                    name={RegistrationFormFields.LastName}
                    ref={this.lastNameText}
                    placeholder="Last Name"
                    disabled={!!this.props.token.lastName}
                    hasError={fieldErrors.indexOf(RegistrationFormFields.LastName) >= 0}
                    value={this.state[RegistrationFormFields.LastName]}
                    onChange={this.handleChange}
                    {...textInputBaseProps} />
                </Box>
              </Flex>
              <Box mb="2">
                <PasswordInput
                  name={RegistrationFormFields.NewPassword}
                  innerRef={this.passwordText}
                  placeholder="Password"
                  hasError={fieldErrors.indexOf(RegistrationFormFields.NewPassword) >= 0}
                  onChange={this.handleChange}
                  {...textInputBaseProps} />
              </Box>
              <Box mb="3">
                <NewPasswordContainer password={this.state[RegistrationFormFields.NewPassword]} />
              </Box>
              <Box mb="4">
                <CreateAccountTerms />
              </Box>
              {this.props.errors.length > 0 && (
                <Box mb={3}>
                  <Toast variant={ToastVariant.Danger}>
                    <Box fg={Palette.black}>
                      <p>The following fields are required:</p>
                      <ul>{RegistrationFormContainer.renderValidationErrors(this.props.errors)}</ul>
                    </Box>
                  </Toast>
                </Box>
              )}
              <Box>
                <Button type="submit" large inverse stretch disabled={this.props.registrationState.isFetching}>Create Account</Button>
              </Box>
            </form>
          </React.Fragment>
        )}
      </Box>
    );
  }
}

export const RegistrationFormContainerWithFormValidation = withFormValidation<RegistrationFormContainerProps>(RegistrationFormContainer);

interface StateProps {
  locationId: number;
  registrationState: RegistrationState;
}

const mapStateToProps = (state: SelfServiceCombinedReducers): StateProps => {
  return {
    locationId: state.appReducer.locationInfo.locationId,
    registrationState: state.registrationReducer,
  };
};

const connectedRegistrationFormContainer = connect<StateProps, any, {}>(mapStateToProps)(RegistrationFormContainerWithFormValidation);

export default connectedRegistrationFormContainer;
