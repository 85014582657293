import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, BasicType, TextInput, Button, Palette, Toast, ToastVariant } from '@mycelium/core';
import * as Promise from 'bluebird';
import PasswordInput from './presentation/PasswordInput';
import withFormValidation, { WithFormValidationProps } from './utility/withFormValidation';
import { emailField, requiredField } from '../utils/validators';
import * as SelfServiceAPI from '../typings/SelfServiceAPI';

import logo from './../assets/club-os-logo-one-color-white.svg';

const INPUT_NAME = 'inputName';
const EMAIL = 'email';
const PASSWORD = 'password';

const styles: {
  whiteLink: string,
  submitContainer: string
} = require('../styles/login.module.css');

interface LoginProps {
  locationInfo: SelfServiceAPI.Location;
  afterValidate: (errors: {}[]) => void;
  resetFailedLogin: () => void;
  validationErrors: JSX.Element | null;
  updateEmailAndPassword: (email: string, password: string) => void;
}

interface LoginWithFormValidationProps extends WithFormValidationProps, LoginProps {}

class Login extends React.Component<LoginWithFormValidationProps, any> {
  readonly emailText = React.createRef<any>();
  readonly passwordText = React.createRef<any>();

  constructor(props: LoginWithFormValidationProps) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  componentDidMount() {
    this.props.registerValidators([
      {
        name: 'emailText',
        validator: () => Promise.all([
          requiredField(this.emailText, { message: 'Email cannot be empty', ref: this.emailText, [INPUT_NAME]: EMAIL }),
          emailField(this.emailText, { message: 'Email must be a valid email address', ref: this.emailText, [INPUT_NAME]: EMAIL })
        ])
      },
      {
        name: 'passwordText',
        validator: () => requiredField(
          this.passwordText, { message: 'Password cannot be empty', ref: this.passwordText, [INPUT_NAME]: PASSWORD }
        )
      }
    ]);
  }

  handleChange(event: any) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // TODO: Should not use keypress to handle submit, instead use form.
  handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      this.handleValidate(e);
    }
  }

  handleValidate(e: any) {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.resetFailedLogin();
    this.props.updateEmailAndPassword(email, password);
    this.props.validate(undefined, this.props.afterValidate);
  }

  private renderLogin() {
    const {
      errors,
      validationErrors,
    } = this.props;
    const borderColor = 'rgba(255,255,255,0.3)';
    const mediaQueryProps = {
      sm: {
        p: 5,
        ba: 1,
        borderRound: true,
        borderColor,
      },
    };
    return (
      <Box bb={1} pb={5} borderColor={borderColor} {...mediaQueryProps}>
        <Box mb={2}>
          <TextInput
            type="text"
            ref={this.emailText}
            value={this.state.email}
            placeholder="Email"
            required
            large
            frosted
            hasError={!!errors.find(error => error[INPUT_NAME] === EMAIL)}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            name={EMAIL}
          />
        </Box>
        <Box mb={3}>
          <PasswordInput
            innerRef={this.passwordText}
            value={this.state.password}
            placeholder="Password"
            required
            large
            frosted
            hasError={!!errors.find(error => error[INPUT_NAME] === PASSWORD)}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            name={PASSWORD}
          />
        </Box>
        {validationErrors && (
          <Box mb={3}>
            <Toast variant={ToastVariant.Danger}>
              {validationErrors}
            </Toast>
          </Box>
        )}
        <div className={styles.submitContainer}>
          <Button large inverse onClick={this.handleValidate} type="submit">
            Log in
          </Button>
        </div>
      </Box>
    );
  }

  private renderCallOut() {
    const {
      locationInfo: {
        locationId,
        clubName,
        locationName,
      },
    } = this.props;
    const mediaQueryProps = {
      sm: {
        px: 5,
      }
    };
    return (
      <Box fg={Palette.white} textAlign="center" {...mediaQueryProps}>
        <Box my={5}>
          <BasicType sizePx="16" style={{ fontWeight: 'bold' }}>
            Don’t have a Club OS Account?
          </BasicType>
        </Box>
        <Link to={`/locations/${locationId}/create-account`}>
          <Button large ghost inverse style={{ width: '100%' }}>
            Create an account
          </Button>
        </Link>
        <Box my={5}>
          <BasicType sizePx="13">
            Your Club OS account can be used to book and purchase sessions at {clubName} - {locationName} and
            3,000+ other fitness locations.
          </BasicType>
        </Box>
        <Box my={5}>
          <img src={logo} style={{ width: '105px' }}/>
        </Box>
      </Box>
    );
  }

  public render() {
    return (
      <React.Fragment>
        {this.renderLogin()}
        {this.renderCallOut()}
      </React.Fragment>
    );
  }
}

const LoginWithFormValidation = withFormValidation<LoginProps>(Login);

export default LoginWithFormValidation;
