import * as React from 'react';
import { BasicType, Box } from '@mycelium/core';

export interface BookingEventNotFoundProps {
}

const BookingEventNotFound: React.SFC<BookingEventNotFoundProps> = () => (
  <Box my="3" xs={{ px: 3 }} md={{ p: 0 }}>
    <BasicType size="2">Event Not Found!</BasicType>
  </Box>
);

export default BookingEventNotFound;
