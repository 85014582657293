// @ts-ignore
import * as debounce from 'lodash.debounce';

export interface DetectScrollBottomConfig {
  bottomThreshold: number;
  onScrollBottom: () => void;
  _window?: Window;
  _document?: Document;
}

export class DetectScrollBottom {
  private readonly window: Window;
  private readonly document: Document;

  constructor(private readonly config: DetectScrollBottomConfig) {
    this.window = config._window || window;
    this.document = config._document || document;
    this.handleScroll = debounce(this.handleScroll.bind(this), 200);
    this.window.addEventListener('scroll', this.handleScroll);
  }

  public dispose(): void {
    this.window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll(): void {
    const { bottomThreshold, onScrollBottom } = this.config;
    if (this.getDiff() < bottomThreshold) {
      onScrollBottom();
    }
  }

  private getDiff(): number {
    const viewportHeight: number = this.window.innerHeight;
    const scrollPosition: number = this.window.pageYOffset;
    const scrollHeight: number = this.document.body.scrollHeight;
    return scrollHeight - (scrollPosition + viewportHeight);
  }
}
