import { createBrowserHistory } from 'history';

// Router history singleton
const appHistory = createBrowserHistory();

if (process.env.REACT_APP_GA_TRACKING_CODE) {
  const analyticsService = require('../services/analyticsService');
  analyticsService.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

  appHistory.listen(() => {
    analyticsService.pageView();
  });
}

export default appHistory;
