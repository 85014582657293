import * as qs from 'qs';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  Credentials,
  Event,
  EventAttendee,
  EventRequest,
  EventsRequest,
  FetchActiveAccountMetadata,
  Location,
  PatchAccountEventAttendee,
  AccountMetadata,
} from '../typings/SelfServiceAPI';
import { getAuthorizationHeader, validateAuthorization } from '../services/authorizationService';
import { RegistrationAccount } from '../typings/registration';

export const DATA_ATTR: string = 'data';
const selfServiceAPIConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SELF_SERVICE_API_URL
};

const selfServiceAPI: AxiosInstance = axios.create(selfServiceAPIConfig);
const paramsSerializer = (params: any): string => qs.stringify(params, { indices: false });

// Validation
selfServiceAPI.interceptors.response.use((response) => {
  validateAuthorization(response);
  return response;
}, (error) => {
  validateAuthorization(error.response);
  return Promise.reject(error);
});

// Locations
export const requestLocationInfoById = (locationId: number): Promise<Location> => {
  return selfServiceAPI.get(`/locations/${locationId}`, {
    headers: getAuthorizationHeader(),
    params: {
      locationId,
      requestTimeStamp: Date.now(),  // IE11 fix
    },
    paramsSerializer,
  }).then((response) => {
    return response.data[DATA_ATTR];
  });
};

// Schedule
export const fetchScheduleByEventsRequest =
  (eventsRequest: EventsRequest): Promise<Event[]> => {
    return selfServiceAPI.get(
      `/locations/${eventsRequest.locationId}/events`, {
        headers: getAuthorizationHeader(),
        params: {
          ...eventsRequest,
          requestTimeStamp: Date.now(),  // IE11 fix
        },
        paramsSerializer,
      }
    ).then((response) => {
      return response.data[DATA_ATTR];
    });
  };

// Event
export const fetchEventByEventId =
  (eventRequest: EventRequest): Promise<Event> => {
    return selfServiceAPI.get(
      `/events/${eventRequest.eventId}`, {
        headers: getAuthorizationHeader(),
        params: {
          locationId: eventRequest.locationId,
          eventId: eventRequest.eventId,
          startTimeStartAt: eventRequest.startDatetime,
          requestTimeStamp: Date.now(),  // IE11 fix
        },
        paramsSerializer,
      }
    ).then((response) => {
      return response.data[DATA_ATTR];
    });
  };

export const cancelScheduledEvent =
  (locationId: number, event: SelfServiceAPI.Event): Promise<void> => {
    return selfServiceAPI.patch(
      `/events/${event.id}/attendees`,
      {
        startDateTime: event.startTime,
        attendeeStatus: 'CN',
      },
      {
        headers: getAuthorizationHeader(),
        params: {
          locationId,
        },
        paramsSerializer,
      },
    ).then((response) => {
      return response.status === 200
        ? Promise.resolve()
        : Promise.reject({ response });
    });
  };

export const getStaffProfileUrl = (staffId: number, staffProfilePictureHash: string): string =>
  staffId && staffProfilePictureHash
    ? `https://s3.amazonaws.com/test-usercontent/photos/${staffId}/profile/50.jpg?h=${staffProfilePictureHash}`
    : '';

// Accounts
export const postRegistrationAccount = (registrationAccount: RegistrationAccount) => {
  return selfServiceAPI.post(
    '/accounts',
    {
      token: registrationAccount.token,
      firstName: registrationAccount.firstName,
      lastName: registrationAccount.lastName,
      password: registrationAccount.password,
    },
    {
      params: {
        locationId: registrationAccount.locationId,
      },
      paramsSerializer,
    }
  ).then((response) => {
    return response.data[DATA_ATTR];
  });
};

export const postAuthenticationCredentials = (locationId: number, credentials: Credentials): Promise<any> => {
  return selfServiceAPI.post(
    '/accounts/authenticate',
    credentials,
    {
      params: { locationId },
      paramsSerializer,
    },
  )
    .then((response) => {
      return response.data;
    });
};

// Account: note that /account endpoints use information from the auth token to resolve the user id
export const verifyAccountByEmailAndLocationId = (email: string, locationId: number): Promise<any> => {
  return selfServiceAPI.post(
    '/account/verify',
    { email, locationId },
    {
      params: { locationId },
      paramsSerializer,
    },
  )
    .then((response) => {
      return response.data;
    });
};

export const fetchActiveAccountMetadata: FetchActiveAccountMetadata = (locationId: number): Promise<AccountMetadata> => {
  return selfServiceAPI.get('/account/member-services', {
    headers: getAuthorizationHeader(),
    params: {
      locationId,
      requestTimeStamp: Date.now(),  // IE11 fix
    },
    paramsSerializer,
  }).then((response) => {
    return response.data[DATA_ATTR];
  });
};

export const patchAccountEventAttendee: PatchAccountEventAttendee = (locationId: number, eventId: number, eventAttendee: EventAttendee) => {
  return selfServiceAPI.patch(
    `/events/${eventId}/attendees`,
    eventAttendee,
    {
      headers: getAuthorizationHeader(),
      params: { locationId },
      paramsSerializer,
    }
  ).then((response) => {
    return response.data[DATA_ATTR];
  });
};
