import * as React from 'react';
import { BasicType, Box, Button, formatPhoneNumber, Palette } from '@mycelium/core';
import * as SelfServiceAPI from '../../typings/SelfServiceAPI';

export interface BookingDetailsProps {
  isFetching: boolean;
  locationInfo: SelfServiceAPI.Location;
  handleBookClick: () => void;
  memberServiceName: string;
  bookableUnits: number;
}

const BookingDetails: React.SFC<BookingDetailsProps> = (props) => {

  const hasBookableUnits = props.bookableUnits > 0;
  const bookingDisabled = props.isFetching || !hasBookableUnits;
  const {
    locationInfo: {
      clubName,
      locationName,
      locationPhoneNumber,
    }
  } = props;

  return (
    <React.Fragment>
      <Box p="4" ba="1" bt="0" bg={Palette.lighterGrey}>
        <BasicType sizePx="20">
          <Box dp="inline-block" mr="3">Total:</Box>
          <span>1 {props.memberServiceName} session</span>
        </BasicType>
      </Box>
      <Box p="4" ba="1" bt="0" bg={Palette.lightBlue}>
        <Box mb="4">
          <BasicType sizePx="20">Pay with Sessions</BasicType>
        </Box>
        <Box mb="4">
          {hasBookableUnits ? (
            <p>Upon clicking the confirmation button below, 1 {props.memberServiceName} session will be deducted from your
              account.</p>
          ) : (
            <React.Fragment>
              <p>
                <b>You do not currently have sessions for this class type.</b>
              </p>
              <p>To book sessions online, you must purchase them by contacting {clubName} {locationName} at <a
                href={`tel:${locationPhoneNumber}`}>{formatPhoneNumber(locationPhoneNumber)}</a> or in person.</p>
            </React.Fragment>
          )}
        </Box>
        <Button onClick={props.handleBookClick} disabled={bookingDisabled}>Confirm Reservation</Button>
      </Box>
    </React.Fragment>
  );
};

export default BookingDetails;
