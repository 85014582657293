import * as React from 'react';
import { Box, TextInput, Button, Toast, ToastVariant } from '@mycelium/core';
import * as Promise from 'bluebird';
import withFormValidation, { WithFormValidationProps } from '../utility/withFormValidation';
import { emailField, requiredField } from '../../utils/validators';

const INPUT_NAME = 'inputName';
const EMAIL = 'email';

const styles: {
  heading1: string,
  heading2: string,
  whiteLink: string,
  submitContainer: string
} = require('../../styles/login.module.css');

interface CreateAccountProps {
  navigateToLogin: (e: any) => void;
  resetVerifyAccount: () => void;
  updateEmail: (email: string) => void;
  validationErrors: JSX.Element | null;
}

interface CreateAccountWithFormValidationProps extends WithFormValidationProps, CreateAccountProps {}

class CreateAccountForm extends React.Component<CreateAccountWithFormValidationProps, any> {
  readonly emailText = React.createRef<any>();

  constructor(props: CreateAccountWithFormValidationProps) {
    super(props);
    this.state = {
      email: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  componentDidMount() {
    this.props.registerValidators([
      {
        name: 'emailText',
        validator: () => Promise.all([
          requiredField(this.emailText, { message: 'Email cannot be empty', ref: this.emailText, [INPUT_NAME]: EMAIL }),
          emailField(this.emailText, { message: 'Email must be a valid email address', ref: this.emailText, [INPUT_NAME]: EMAIL })
        ])
      }
    ]);
  }

  handleChange(event: any) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidate(e: any) {
    e.preventDefault();
    const { email } = this.state;
    this.props.resetVerifyAccount();
    this.props.updateEmail(email);
    this.props.validate();
  }

  public render() {
    const {
      errors,
      validationErrors,
    } = this.props;
    return (
      <React.Fragment>
        <Box mb={4}>
          <h1 className={styles.heading1}>Create Account</h1>
        </Box>
        <Box mb={5}>
          <h2 className={styles.heading2}>What is your email?</h2>
        </Box>
        <form onSubmit={this.handleValidate} noValidate>
          <Box mb={3}>
            <TextInput
              type="text"
              ref={this.emailText}
              value={this.state.email}
              placeholder="Email"
              large
              required
              aria-required="true"
              frosted
              hasError={!!errors.find(error => error[INPUT_NAME] === EMAIL)}
              onChange={this.handleChange}
              name={EMAIL}
            />
          </Box>
          {validationErrors && (
            <Box mb={3}>
              <Toast variant={ToastVariant.Danger}>
                {validationErrors}
              </Toast>
            </Box>
          )}
          <Box mb={6}>
            <Button large inverse stretch type="submit">
              Continue
            </Button>
          </Box>
        </form>
        <Box textAlign="center">
          <a href="#" className={styles.whiteLink} onClick={(e) => this.props.navigateToLogin(e)}>
            Log In to Existing Account
          </a>
        </Box>
      </React.Fragment>
    );
  }
}

const LoginWithFormValidation = withFormValidation<CreateAccountProps>(CreateAccountForm);

export default LoginWithFormValidation;
